import { theme } from "antd";

export default function SubmissionApproved() {
    const { token: { ap_userSecondaryColor }, } = theme.useToken();
    return (
        <svg width="60" height="60" viewBox="0 0 50 50" fill="none" xmlns="http://www.w3.org/2000/svg">
            <rect width="50" height="50" rx="10" fill={ap_userSecondaryColor} />
            <path d="M22.4334 28.3L18.4917 24.3583C18.1556 24.0222 17.7278 23.8541 17.2084 23.8541C16.6889 23.8541 16.2611 24.0222 15.925 24.3583C15.5889 24.6944 15.4209 25.1222 15.4209 25.6416C15.4209 26.1611 15.5889 26.5888 15.925 26.925L21.15 32.15C21.5167 32.5166 21.9445 32.7 22.4334 32.7C22.9222 32.7 23.35 32.5166 23.7167 32.15L34.075 21.7916C34.4111 21.4555 34.5792 21.0277 34.5792 20.5083C34.5792 19.9888 34.4111 19.5611 34.075 19.225C33.7389 18.8888 33.3111 18.7208 32.7917 18.7208C32.2722 18.7208 31.8445 18.8888 31.5084 19.225L22.4334 28.3ZM25 43.3333C22.4639 43.3333 20.0806 42.8517 17.85 41.8886C15.6195 40.9255 13.6792 39.6196 12.0292 37.9708C10.3792 36.3208 9.07324 34.3805 8.11135 32.15C7.14946 29.9194 6.66791 27.5361 6.66669 25C6.66669 22.4638 7.14824 20.0805 8.11135 17.85C9.07446 15.6194 10.3804 13.6791 12.0292 12.0291C13.6792 10.3791 15.6195 9.07318 17.85 8.11129C20.0806 7.1494 22.4639 6.66785 25 6.66663C27.5361 6.66663 29.9195 7.14818 32.15 8.11129C34.3806 9.0744 36.3209 10.3803 37.9709 12.0291C39.6208 13.6791 40.9274 15.6194 41.8905 17.85C42.8536 20.0805 43.3346 22.4638 43.3333 25C43.3333 27.5361 42.8518 29.9194 41.8887 32.15C40.9256 34.3805 39.6196 36.3208 37.9709 37.9708C36.3209 39.6208 34.3806 40.9273 32.15 41.8905C29.9195 42.8536 27.5361 43.3345 25 43.3333ZM25 39.6666C29.0945 39.6666 32.5625 38.2458 35.4042 35.4041C38.2458 32.5625 39.6667 29.0944 39.6667 25C39.6667 20.9055 38.2458 17.4375 35.4042 14.5958C32.5625 11.7541 29.0945 10.3333 25 10.3333C20.9056 10.3333 17.4375 11.7541 14.5959 14.5958C11.7542 17.4375 10.3334 20.9055 10.3334 25C10.3334 29.0944 11.7542 32.5625 14.5959 35.4041C17.4375 38.2458 20.9056 39.6666 25 39.6666Z" fill="white" />
        </svg>
    );
}