import { theme } from "antd";

export default function CreditExtended() {
    const { token: { ap_userSecondaryColor }, } = theme.useToken();
    return (
        <svg width="60" height="60" viewBox="0 0 50 50" fill="none" xmlns="http://www.w3.org/2000/svg">
            <rect width="50" height="50" rx="10" fill={ap_userSecondaryColor} />
            <path fillRule="evenodd" clipRule="evenodd" d="M15.8928 42.05C17.8948 42.9055 20.0416 43.3333 22.3333 43.3333C26.946 43.3345 30.8491 41.7383 34.0428 38.5446C37.2365 35.351 38.8333 31.4472 38.8333 26.8333V26.7416C38.8333 26.2845 38.65 25.88 38.2833 25.528C37.9166 25.176 37.4889 25 37 25C36.4818 25 36.0466 25.1986 35.6946 25.5958C35.3426 25.993 35.1666 26.4514 35.1666 26.9708C35.1068 30.5458 33.8466 33.5555 31.3863 36C28.926 38.4444 25.9083 39.6666 22.3333 39.6666C18.7278 39.6666 15.6875 38.4291 13.2125 35.9541C10.7375 33.4791 9.49998 30.4389 9.49998 26.8333C9.49998 23.2277 10.7375 20.1875 13.2125 17.7125C15.6875 15.2375 18.7278 14 22.3333 14C22.5778 14.0012 22.8222 14.0091 23.0666 14.0238C23.3111 14.0385 23.5555 14.0611 23.8 14.0916C24.4404 14.154 24.9672 14.0244 25.3803 13.703C25.7934 13.3815 26 12.9152 26 12.3041C26 11.8776 25.8625 11.4883 25.5875 11.1363C25.3125 10.7843 24.9764 10.5777 24.5791 10.5166C24.2125 10.4555 23.8458 10.4097 23.4791 10.3791C23.1125 10.3486 22.7305 10.3333 22.3333 10.3333C17.7206 10.3321 13.8175 11.9283 10.6238 15.122C7.43015 18.3156 5.83331 22.2194 5.83331 26.8333C5.83331 29.1237 6.26109 31.27 7.11665 33.272C7.9722 35.274 9.14859 37.0236 10.6458 38.5208C12.1418 40.018 13.8908 41.1944 15.8928 42.05ZM21.05 28.1166L26.1833 33.25C26.5194 33.5861 26.9472 33.7541 27.4666 33.7541C27.9861 33.7541 28.4139 33.5861 28.75 33.25C29.0861 32.9139 29.2541 32.4861 29.2541 31.9666C29.2541 31.4472 29.0861 31.0194 28.75 30.6833L24.1666 26.1V19.5C24.1679 18.9817 23.9925 18.5466 23.6405 18.1946C23.2885 17.8426 22.8528 17.6666 22.3333 17.6666C21.8151 17.6654 21.38 17.8408 21.028 18.1928C20.676 18.5448 20.5 18.9805 20.5 19.5V26.7875C20.5 27.0331 20.5458 27.2702 20.6375 27.4988C20.7291 27.7274 20.8666 27.9333 21.05 28.1166Z" fill="white" />
            <path d="M37 21.3333C36.4805 21.3333 36.0448 21.1573 35.6928 20.8053C35.3408 20.4533 35.1654 20.0182 35.1666 19.5V15.8333H31.5C30.9805 15.8333 30.5448 15.6573 30.1928 15.3053C29.8408 14.9533 29.6654 14.5182 29.6666 14C29.6666 13.4805 29.8426 13.0448 30.1946 12.6928C30.5466 12.3408 30.9818 12.1654 31.5 12.1666H35.1666V8.49997C35.1666 7.98052 35.3426 7.5448 35.6946 7.1928C36.0466 6.8408 36.4818 6.66541 37 6.66663C37.5194 6.66663 37.9551 6.84263 38.3071 7.19463C38.6591 7.54663 38.8345 7.98174 38.8333 8.49997V12.1666H42.5C43.0194 12.1666 43.4551 12.3426 43.8071 12.6946C44.1591 13.0466 44.3345 13.4817 44.3333 14C44.3333 14.5194 44.1573 14.9551 43.8053 15.3071C43.4533 15.6591 43.0182 15.8345 42.5 15.8333H38.8333V19.5C38.8333 20.0194 38.6573 20.4551 38.3053 20.8071C37.9533 21.1591 37.5182 21.3345 37 21.3333Z" fill="#645A80" />
        </svg>
    );
}