import { theme } from "antd";

export default function NewApplication() {
    const { token: { ap_userSecondaryColor }, } = theme.useToken();
    return (
        <svg width="60" height="60" viewBox="0 0 50 50" fill="none" xmlns="http://www.w3.org/2000/svg">
            <rect width="50" height="50" rx="10" fill={ap_userSecondaryColor} />
            <path d="M14 30.5C14 31.0194 14.176 31.4551 14.528 31.8071C14.88 32.1591 15.3151 32.3345 15.8334 32.3333H21.4709C21.5625 31.6916 21.708 31.0652 21.9072 30.4541C22.1064 29.843 22.3276 29.2472 22.5709 28.6666H15.8334C15.3139 28.6666 14.8782 28.8426 14.5262 29.1946C14.1742 29.5466 13.9988 29.9817 14 30.5Z" fill="white" />
            <path d="M14 23.1666C14 23.6861 14.176 24.1218 14.528 24.4738C14.88 24.8258 15.3151 25.0012 15.8334 25H25.1834C26.1611 24.0833 27.2538 23.3194 28.4614 22.7083C29.6689 22.0972 30.9596 21.6847 32.3334 21.4708C32.0584 21.4097 31.7528 21.3712 31.4167 21.3553C31.0806 21.3394 30.775 21.3321 30.5 21.3333H15.8334C15.3139 21.3333 14.8782 21.5093 14.5262 21.8613C14.1742 22.2133 13.9988 22.6484 14 23.1666Z" fill="white" />
            <path d="M14 15.8333C14 16.3527 14.176 16.7885 14.528 17.1405C14.88 17.4925 15.3151 17.6679 15.8334 17.6666H30.5C31.0195 17.6666 31.4552 17.4906 31.8072 17.1386C32.1592 16.7866 32.3346 16.3515 32.3334 15.8333C32.3334 15.3139 32.1574 14.8781 31.8054 14.5261C31.4534 14.1741 31.0182 13.9987 30.5 14H15.8334C15.3139 14 14.8782 14.176 14.5262 14.528C14.1742 14.88 13.9988 15.3151 14 15.8333Z" fill="white" />
            <path fillRule="evenodd" clipRule="evenodd" d="M10.3334 39.6666C9.32502 39.6666 8.46152 39.3073 7.74286 38.5886C7.02419 37.87 6.66547 37.0071 6.66669 36V10.3333C6.66669 9.32496 7.02602 8.46146 7.74469 7.7428C8.46336 7.02413 9.32625 6.66541 10.3334 6.66663H36C37.0084 6.66663 37.8719 7.02596 38.5905 7.74463C39.3092 8.4633 39.6679 9.32618 39.6667 10.3333V22.6166C39.0861 22.3416 38.4903 22.1045 37.8792 21.9053C37.2681 21.7061 36.6417 21.5612 36 21.4708V10.3333H10.3334V21.3333V21.4708V34.1666V36H21.425C21.5167 36.6722 21.6621 37.3138 21.8614 37.925C22.0606 38.5361 22.2971 39.1166 22.5709 39.6666H10.3334Z" fill="white" />
            <path fillRule="evenodd" clipRule="evenodd" d="M34.1667 43.3333C31.6306 43.3333 29.4685 42.4392 27.6804 40.6511C25.8922 38.863 24.9988 36.7015 25 34.1666C25 31.6305 25.8941 29.4684 27.6822 27.6803C29.4703 25.8922 31.6318 24.9987 34.1667 25C36.7028 25 38.8649 25.894 40.653 27.6821C42.4411 29.4702 43.3346 31.6317 43.3334 34.1666C43.3334 36.7027 42.4393 38.8648 40.6512 40.653C38.8631 42.4411 36.7016 43.3345 34.1667 43.3333ZM33.25 38.75V35.0833H29.5834C29.3389 35.0833 29.125 34.9916 28.9417 34.8083C28.7584 34.625 28.6667 34.4111 28.6667 34.1666C28.6667 33.9222 28.7584 33.7083 28.9417 33.525C29.125 33.3416 29.3389 33.25 29.5834 33.25H33.25V29.5833C33.25 29.3389 33.3417 29.125 33.525 28.9416C33.7084 28.7583 33.9222 28.6666 34.1667 28.6666C34.4111 28.6666 34.625 28.7583 34.8084 28.9416C34.9917 29.125 35.0834 29.3389 35.0834 29.5833V33.25H38.75C38.9945 33.25 39.2084 33.3416 39.3917 33.525C39.575 33.7083 39.6667 33.9222 39.6667 34.1666C39.6667 34.4111 39.575 34.625 39.3917 34.8083C39.2084 34.9916 38.9945 35.0833 38.75 35.0833H35.0834V38.75C35.0834 38.9944 34.9917 39.2083 34.8084 39.3916C34.625 39.575 34.4111 39.6666 34.1667 39.6666C33.9222 39.6666 33.7084 39.575 33.525 39.3916C33.3417 39.2083 33.25 38.9944 33.25 38.75Z" fill="#645A80" />
        </svg>

    );
}