import ProfileUser from "../ProfileUser";
import ProfileBusinessDetails from "../ProfileBusinessDetails";
import ContactsBlock from "../ContactsBlock";
import { useState } from "react";
import Error404 from "../../layouts/Error404"
import { Spin, Tabs } from "antd";
import styles from "./index.module.css";
import { useUpdateActiveTab } from "../../utils/UpdateActiveTab";

export default function Profile() {

    // TODO: agentId to be fetched from application memory in the future
    const agentId = JSON.parse(localStorage?.getItem("user"))?.agent_id;

    const [refresh, setRefresh] = useState(0);
    const [error, setError] = useState(false);
    const [loader, setLoader] = useState({ loading: false, tip: '' });

    const tabItems = [
        {
            key: 'businessDetails',
            label: 'Business Details',
            children: <ProfileBusinessDetails agentId={agentId} setError={setError} setLoader={setLoader} />,
        },
        {
            key: 'contacts',
            label: 'Contacts',
            children: <ContactsBlock entity='agent' entityId={agentId} refresh={refresh} setRefresh={setRefresh} setError={setError} />,
        }
    ];

    const [activeTab, setActiveTab] = useState(tabItems[0].key);

    // custom hook to update the active tab based on the query params
    useUpdateActiveTab(activeTab, setActiveTab, tabItems, tabItems[0]?.key);

    return (
        !error ? (
            <Spin spinning={loader?.loading} tip={loader?.tip}>
                <div>
                    <ProfileUser agentId={agentId} setError={setError} setLoader={setLoader} />

                    <div className={styles['Container']}>
                        <Tabs
                            activeKey={activeTab}
                            defaultActiveKey={tabItems[0].key}
                            items={tabItems}
                            onChange={(key) => setActiveTab(key)}
                        />
                    </div>
                </div>
            </Spin>
        ) : (
            <div style={{ overflow: "hidden", height: "70vh" }}><Error404 /></div>)
    );
}