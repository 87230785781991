import { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import { ConfigProvider, Table, theme } from "antd";
import BeaconPagination from "../../components/BeaconPagination";
import ApiService from "../../api";
import completedConstants from "./constants";

import styles from "./index.module.css";
import utils from "../../utils";

export default function CompletedDrawdownsList({ entity, entityId }) {

    const { token: { themeFontColor, themePrimaryColor }, } = theme.useToken();

    const navigate = useNavigate();

    const [loading, setLoading] = useState(true);
    const [uncleanData, setUncleanData] = useState();
    const [cleanData, setCleanData] = useState([]);

    // Search Field
    const [searchText, setSearchText] = useState('');
    const [searchedColumn, setSearchedColumn] = useState('');
    const searchInput = useRef(null);

    const [customSort, setCustomSort] = useState({
        lcNumber: { order: null, type: 'string' },
        clientName: { order: null, type: 'string' },
        lenderName: { order: null, type: 'string' },
        paidOnDate: { order: null, type: 'date' },
    });

    useEffect(() => {
        setLoading(true);
        if (entity === 'agent')
            ApiService.fetchAgentCompletedDrawdowns(entityId).then((data) => {
                setUncleanData(data);
            });
        else if (entity === 'client')
            ApiService.fetchClientCompletedDrawdowns(entityId).then((data) => {
                setUncleanData(data);
            });
        setLoading(false);
    }, [entity, entityId]);

    useEffect(() => {
        const tempData = [];
        for (let i = 0; i < uncleanData?.length; i++) {
            tempData.push({
                id: uncleanData[i]?._id,
                lcNumber: uncleanData[i]?.crm_lcNumber,
                clientName: uncleanData[i]?.ap_clientId?.ap_businessName,
                assetDescription: uncleanData[i]?.ap_assetDescription,
                lenderName: uncleanData[i]?.ap_lenderId?.ap_abbreviation || uncleanData[i]?.ap_lenderId?.ap_name,
                agentFee: uncleanData[i]?.ap_agentFee,
                drawdownFee: uncleanData[i]?.ap_drawdownFee,
                paidOnDate: uncleanData[i]?.ap_agentFeePaidOnDate,
                invoiceLink: uncleanData[i]?.ap_invoiceLink ? utils.FormBlobFileUrl(uncleanData[i]?.ap_invoiceLink) : null,
            });
        }
        setCleanData(tempData);
    }, [uncleanData]);

    const { pagedData, paginationComponent } = BeaconPagination(cleanData, CompletedDrawdownsList.name, true, true, searchedColumn, searchText, {}, customSort);

    return (
        <ConfigProvider
            theme={{
                token: {
                    colorBgContainer: themePrimaryColor,
                    colorFillAlter: themePrimaryColor,
                    colorFillSecondary: themePrimaryColor,
                    colorText: themeFontColor,
                    colorIcon: themeFontColor,
                    colorTextPlaceholder: themeFontColor,
                    borderRadius: '50',
                },
            }}
        >
            <Table
                id={CompletedDrawdownsList.name}
                columns={completedConstants.generateTableColumns(searchText, setSearchText, searchedColumn, setSearchedColumn, searchInput, navigate, entity, customSort, setCustomSort)}
                dataSource={pagedData}
                loading={loading}
                scroll={{ x: true, y: 400 }}
                onRow={(record) => ({
                    onDoubleClick: () => {
                        navigate(`/drawdowns/${record.id}`);
                    },
                    className: styles["CompletedDrawdownRow"]
                })}
                pagination={false}
                footer={() => paginationComponent}
            />
        </ConfigProvider>
    );
}