import { useNavigate } from "react-router-dom";
import { Button, theme, ConfigProvider, Table } from "antd";

import generateDetails from "./constants";
import { constants } from "./constants.js";

import NotificationDetailsBlock from "../../NotificationDetailBlock";
import validate from "../../../utils/Validations";

import NotificationScreenIcons from "../../../assets/images/icons/Notifications/NotificationScreenIcons";

import styles from "./index.module.css";

export default function AssetDeclined({ notificationData }) {

    const navigate = useNavigate();
    const { token: { ap_userPrimaryColor, themePrimaryColor, themeFontColor }, } = theme.useToken();

    const clientPhone = `tel:${notificationData.ap_data.clientPhone}`
    const clientEmail = `mailto:${notificationData.ap_data.clientEmail}`

    const details = generateDetails(notificationData.ap_data);

    return (
        <div className={styles["container"]}>
            <div><NotificationScreenIcons.AssetDeclinedIcon /></div>

            <div className={styles["NotificationTitle"]}>ASSET CHANGE DECLINED</div>

            <div>
                Unable to secure to a proposed payment schedule from your request. Below is the current payment schedule available for funding.
            </div>

            <div style={{ padding: '2% 0%', fontWeight: 'bold', fontSize: '14px' }}>
                <div style={{ textTransform: 'uppercase' }}>{notificationData.ap_data.clientName}</div>
                <div>
                    <a href={clientPhone}>{validate.FormatTelephone(String(notificationData.ap_data.clientPhone)).input}</a> | <a href={clientEmail}>{notificationData.ap_data.clientEmail}</a>
                </div>
            </div>

            <div className={styles["NotificationGridBlock"]} >
                <div className={styles["NotificationDetailsGrid"]} >
                    <NotificationDetailsBlock
                        // style = {{padding: '1%' }}
                        data={details.summaryDetails}
                        lessPadding={true}
                    />
                </div>
                <div className={styles["NotificationDetailsGrid"]} >
                    <div className={styles["Notification_RequirementListContainer"]}>
                        <ConfigProvider
                            theme={{
                                token: {
                                    headerColor: 'white',
                                    colorFillAlter: ap_userPrimaryColor,
                                    // colorText: 'white',
                                    colorBgContainer: themePrimaryColor,
                                    colorFillSecondary: themePrimaryColor,
                                    // colorText: themeFontColor,
                                    colorIcon: themeFontColor,
                                    colorTextPlaceholder: themeFontColor
                                }
                            }}
                        >
                            <div className={styles["Assets_Container"]}>
                                <div className={styles["tableHeader"]}>Assets</div>
                                <Table
                                    columns={constants.assetTableColumns}
                                    dataSource={notificationData.ap_data.ap_assetData}
                                    scroll={{ x: true }}
                                    pagination={false}
                                />
                            </div>
                        </ConfigProvider>
                    </div>
                </div>
            </div>

            <div style={{ padding: "3%" }}>
                <Button
                    type="primary"
                    onClick={() => navigate(`/approvals/${notificationData.ap_entityId}`)}
                    style={{ height: "40px" }}
                >View Approval</Button>
            </div>
        </div>
    );
}