import validate from "../../utils/Validations";
import utils from '../../utils';

/**
 * 
 * @param {*} serialNum 
 * @returns If Serial Number is VIN Number
 */
const ifSerialNumberIsVin = (serialNum) => {
    return (serialNum && !validate.ValidateVIN(serialNum).error)
}

/**
 * Validates whole AssetRow and returns { isError: true, errorMessage: "Appropriate Error Message if Exist" } 
 * 
 * @param {*} assetData 
 * @param {*} isYardAddressRequired 
 * @returns { isError: boolean, errorMessage: string} 
 */
const handleSaveRowValidation = (assetData, isYardAddressRequired) => {
    let validationType = "";
    let assetType = assetData.ap_type;
    let isError = false;
    let errorMessage = "";
    const isPriceNotAvailable = !assetData.ap_price || !!validate.ValidateCurrency(String(assetData.ap_price)).error;
    if(['Trucks', 'Trailers', 'Reefers', 'Dump Truck'].includes(assetType)){
        validationType = "isTruckOrTrailerOrReeferOrDumpTruck";
    }else if((assetType === 'Construction Equipment')){
        validationType = "ConstructionEquipment";
    }else if((assetType === 'Other')){
        validationType = "Other";
    }else{
        validationType = "PalOrOther";
    }

    if(assetData.ap_year){
        errorMessage = utils.ValidateYear(String(assetData.ap_year)).error || errorMessage;
    }
    if(assetData.ap_kms){
        errorMessage = validate.ValidateKMS(assetData.ap_kms).error || errorMessage;
    }
    if(assetData.ap_price){
        errorMessage = validate.ValidateCurrency(String(assetData.ap_price)).error || errorMessage;
    }
    if(assetData.ap_vin){
        errorMessage = validate.ValidateVIN(assetData?.ap_vin).error || errorMessage;
    }

    switch(validationType){
        case "isTruckOrTrailerOrReeferOrDumpTruck":{
            const isRequiredFieldsNotAvailable = (!assetData.ap_make || !assetData.ap_platingprovince || !assetData.ap_model 
                    || !(assetData.ap_year)
                    || !!utils.ValidateYear(String(assetData.ap_year)).error 
                    || !assetData.ap_vin 
                    || !!validate.ValidateVIN(assetData?.ap_vin).error
                    || !!validate.ValidateKMS(assetData.ap_kms).error
                    || !assetData.ap_platingprovince)
            const isYardAddressNotAvailable = (isYardAddressRequired && !assetData.ap_yardaddress);
            isError = isRequiredFieldsNotAvailable || isYardAddressNotAvailable;
            break;
        }
        case "Other" : {
            const isVinSerialAvailable = ifSerialNumberIsVin(assetData['ap_serialNum']);
            const isRequiredFieldsNotAvailable = (!assetData.ap_make || !assetData.ap_platingprovince || !assetData.ap_model 
                || !(assetData.ap_year)
                || !!utils.ValidateYear(String(assetData.ap_year)).error 
                || !!validate.ValidateKMS(assetData.ap_kms).error
                || !assetData.ap_platingprovince)
            const isYardAddressNotAvailable = (isYardAddressRequired && !assetData.ap_yardaddress);
            

            isError = isVinSerialAvailable && (isRequiredFieldsNotAvailable || isYardAddressNotAvailable);
            break;
        }
        case "ConstructionEquipment" : {
            const isVinSerialNotAvailable = assetData['ap_serialNum'] && validate.ValidateSerialNumber(assetData?.ap_serialNum).error;
            isError = isVinSerialNotAvailable;
            break;
        }
        default:{
            isError = false;
            break;
        }
    }
    isError = isPriceNotAvailable || isError;
    return {isError, errorMessage};
}

/**
 * Validate provided fieldName's value from AssetData
 * Returns isNotValidated: true If validation Fail else false
 * 
 * @param {*} assetData 
 * @param {*} fieldName 
 * @returns Boolean
 */
const handleFieldValidation = (assetData, fieldName) => {
    const assetType = assetData['ap_type'];
    const fieldValue = assetData[fieldName];
    let isNotValidated = false;
    
    switch(fieldName){
        case "ap_year": {
            isNotValidated = !fieldValue || utils.ValidateYear(String(fieldValue)).error;
            if(assetType === "Other"){
                isNotValidated = ifSerialNumberIsVin(assetData['ap_serialNum'])  && isNotValidated;
            }
            break;
        }
        case "ap_kms" :{
            isNotValidated = validate.ValidateKMS(fieldValue).error;
            if(assetType === "Other"){
                isNotValidated = ifSerialNumberIsVin(assetData['ap_serialNum'])  && isNotValidated;
            }
            break;
        }
        case "ap_platingprovince" : {
            isNotValidated = !fieldValue;
            if(assetType === "Other"){
                isNotValidated = ifSerialNumberIsVin(assetData['ap_serialNum'])  && isNotValidated;
            }
            break;
        }
        case "ap_yardaddress" : {
            isNotValidated = !assetData['ap_yardaddress'];
            if(assetType === "Other"){
                isNotValidated = ifSerialNumberIsVin(assetData['ap_serialNum'])  && isNotValidated;
            }
            break;
        }
        case "ap_make" : {
            isNotValidated = !fieldValue;
            break;
        }
        case "ap_model" :{
            isNotValidated = !fieldValue;
            break;
        }
        case "ap_vinSerial":{
            if(assetType === 'Construction Equipment'){
                isNotValidated =  assetData.ap_serialNum && validate.ValidateSerialNumber(assetData?.ap_serialNum).error;
            }else if(assetType !== 'Other'){
                isNotValidated = !assetData?.ap_vin || validate.ValidateVIN(assetData?.ap_vin).error;
            }
            break;
        }
        case "ap_price" : {
            isNotValidated = !fieldValue || validate.ValidateCurrency(String(fieldValue)).error;
            break;
        }
        case "ap_vendorId" : {
            isNotValidated = !fieldValue;
            break;
        }
        default: {
            isNotValidated = !fieldValue;
            break;
        }
    }
    return isNotValidated;
}

export {
    handleSaveRowValidation,
    handleFieldValidation
}