// Import necessary modules from React and Ant Design
import { useEffect, useState } from "react";
import { Button, ConfigProvider, Pagination, theme } from "antd";
import * as XLSX from "xlsx";

// Import services and utilities
import appInsightsObj from "../../services/app-insight-service";
import NotificationService from "../../services/notification.service";
import utils from "../../utils";

// Import custom icons
import PreviousIcon from "../../assets/images/icons/PreviousIcon";
import NextIcon from "../../assets/images/icons/NextIcon";

export default function BeaconPagination(tableData, tableId, showPagination = true, showExport = true, searchedColumn = null, searchText = null, filters = {}, customSort = {}) {

    // Fetch and Set User Theme
    const { token: { themeFontColor, themePrimaryColor }, } = theme.useToken();
    const UserTheme = {
        token: {
            colorBgContainer: themePrimaryColor,
            colorFillAlter: themePrimaryColor,
            colorFillSecondary: themePrimaryColor,
            colorText: themeFontColor,
            colorIcon: themeFontColor,
            colorTextPlaceholder: themeFontColor,
            borderRadius: '50%',
        }
    }

    // State variables
    const [finalData, setFinalData] = useState([]); // Final data after filtering and sorting
    const [currentPage, setCurrentPage] = useState(1); // Current page number

    // Number of records per page
    const pageSize = 10;

    // Pagination Logic
    const startIndex = (currentPage - 1) * pageSize;
    const endIndex = startIndex + pageSize;
    const totalItems = finalData?.length;
    const maxPage = Math.ceil(totalItems / pageSize);

    // Function to handle page changes
    const handlePageChange = (page) => setCurrentPage(page);

    // Custom Prevous and Next Buttons
    const itemRender = (_, type, originalElement) => {
        if (type === 'prev')
            return <PreviousIcon enabled={currentPage > 1} />

        if (type === 'next')
            return <NextIcon enabled={currentPage < maxPage} />

        return originalElement;
    };

    // Function to generate Excel workbook
    const generateExcelBook = () => {
        try {
            if (finalData.length <= 0 || !tableId) return;
            const tableElement = document.getElementById(tableId);
            const workbook = XLSX.utils.table_to_book(tableElement);
            const ws = workbook.Sheets["Sheet1"];

            // Download/Write XSLX File
            XLSX.utils.sheet_add_aoa(ws, [[], ["Created " + new Date().toISOString()]], { origin: -1 });
            XLSX.writeFile(workbook, `${tableId}.xlsx`);

            // Notify user upon successful download
            NotificationService.success('Download Complete');
        } catch (error) {
            appInsightsObj.error(error)
            NotificationService.error("Unable to Fetch Excel Sheet! Please try again !!!")
        }
    }

    // Component for pagination
    const paginationComponent = ({ showExport = true }) => {
        return (
            <div style={{ display: "flex", flexDirection: "row" }}>
                <ConfigProvider theme={UserTheme}>
                    <div style={{ flexGrow: 5 }}>
                        {showPagination && (
                            <Pagination
                                total={totalItems}
                                pageSize={pageSize}
                                current={currentPage}
                                onChange={handlePageChange}
                                showSizeChanger={false}
                                itemRender={itemRender}
                            />
                        )}
                    </div>

                    {showExport && (
                        <div >
                            <Button
                                type="primary"
                                style={{ borderRadius: 0 }}
                                onClick={() => generateExcelBook()}
                            >Export Excel</Button>
                        </div>
                    )}
                </ConfigProvider>
            </div>
        );
    };

    // Update filtered data based on changes in tableData, searchText, searchedColumn, filters, and customSort
    useEffect(() => {
        const filterExists = Object.values(filters).some(filter => filter?.length > 0);

        setFinalData(prevData => {
            const sortedData = utils.ProcessSortingRules(customSort, tableData);

            let tData = searchText ? sortedData.filter((data) => {
                return data[searchedColumn]?.toString()?.toLowerCase()?.includes(searchText?.toLowerCase());
            }) : sortedData;

            if (filterExists) {
                Object.keys(filters).forEach((filterKey) => {
                    tData = tData.filter((data) => {
                        return filters[filterKey]?.includes(data[filterKey]);
                    });
                });
            }
            return tData;
        })

        // Reset current page when search text or filters change
        if (searchText || filterExists)
            setCurrentPage(1);
    }, [tableData, searchText, searchedColumn, filters, customSort])

    // Return pagedData and paginationComponent
    return {
        pagedData: showPagination ? finalData?.slice(startIndex, endIndex) : finalData,
        paginationComponent: finalData?.length > 0 ? (paginationComponent({ showExport: showExport })) : (<></>),
    }
}