import constants from "../../constants"

// Generate initial data for the form
function generateInitialData(assetData) {
    const assetType = assetData?.ap_type || '';
    return {
        ap_assetType: {
            value: assetType || '',
            error: '',
            hidden: false,
            required: true,
            inputType: 'dropdown',
            label: 'Asset Type',
            dropdownContent: constants.ASSET_TYPES,
        },
        ap_truckBrand: {
            value: assetData?.ap_truckBrand || '',
            error: '',
            hidden: (assetType && assetType !== 'Dump Truck' && assetType !== 'Trucks'),
            required: false,
            inputType: 'dropdown',
            label: 'Truck Brand',
            dropdownContent: constants.TRUCK_BRANDS,
        },
        ap_trailerBrand: {
            value: assetData?.ap_trailerBrand || '',
            error: '',
            hidden: (assetType && assetType !== 'Reefers' && assetType !== 'Trailers'),
            required: false,
            inputType: 'dropdown',
            label: 'Trailer Brand',
            dropdownContent: constants.TRAILER_BRANDS,
        },
        ap_brand: {
            value: assetData?.ap_brand || '',
            error: '',
            hidden: (assetType && assetType !== 'Construction Equipment' && assetType !== 'Other'),
            required: false,
            inputType: 'text',
            label: 'Brand',
        },
        ap_purpose: {
            value: assetData?.ap_purpose || '',
            error: '',
            hidden: false,
            required: true,
            inputType: 'dropdown',
            label: 'Purpose',
            dropdownContent: constants.ASSET_PURPOSES,
        },
        ap_purposeOther: {
            value: assetData?.ap_purposeOther || '',
            error: '',
            hidden: true,
            required: false,
            inputType: 'text',
            label: 'Purpose (Other)',
        },
        ap_model: {
            value: assetData?.ap_model || '',
            error: '',
            hidden: false,
            required: false,
            inputType: 'text',
            label: 'Model',
        },
        ap_year: {
            value: assetData?.ap_year || '',
            error: '',
            hidden: false,
            required: false,
            inputType: 'text',
            label: 'Year',
            pattern: /^\d{4}$/,
        },
        ap_vin: {
            value: assetData?.ap_vin || '',
            error: '',
            hidden: false,
            required: false,
            inputType: 'text',
            label: 'VIN',
        },
        ap_serialNum: {
            value: assetData?.ap_serialNum || '',
            error: '',
            hidden: false,
            required: false,
            inputType: 'text',
            label: 'Serial #',
        },
        ap_kms: {
            value: assetData?.ap_kms || '',
            error: '',
            hidden: false,
            required: false,
            inputType: 'text',
            label: 'KMs',
        },
        ap_price: {
            value: assetData?.ap_price || '',
            error: '',
            hidden: false,
            required: true,
            inputType: 'text',
            label: 'Price',
            addonBefore: "$",
        },
        ap_vendorId: {
            value: assetData?.ap_vendorId || '',
            error: '',
            hidden: false,
            required: false,
            inputType: 'add-dropdown-search',
            label: 'Vendor',
        },
        ap_platingprovince: {
            value: assetData?.ap_platingprovince || '',
            error: '',
            hidden: false,
            required: false,
            inputType: 'dropdown',
            label: 'Plating Province/States',
            dropdownContent: constants.CANADIAN_PROVINCES,
        },
        ap_platingaddress: {
            value: assetData?.ap_platingaddress || '',
            error: '',
            hidden: false,
            required: false,
            inputType: 'text',
            label: 'Plating Address',
        },
        ap_yardaddress: {
            value: assetData?.ap_yardaddress || '',
            error: '',
            hidden: false,
            required: false,
            inputType: 'text',
            label: 'Yard Address',
        },
    }
}

export default {
    generateInitialData,
}