import { useEffect, useState } from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";

import AuthService from '../services/auth.service';

import JoinUs from "../pages/JoinUs";
import Registration from "../pages/Registration";
import LoginPage from "../pages/LoginPage";
import PasswordReset from "../pages/PasswordReset";
import PasswordResetVerify from "../pages/PasswordResetVerify";
import RegistrationRegenerate from "../pages/RegistrationRegenerate";

import MainLayout from "../layouts/MainLayout";

import HomePage from "../pages/HomePage";
import Error404 from "../layouts/Error404";
import Resources from "../pages/Resources";
import IndividualResource from "../pages/IndividualResourcesPage";

import Client from "../pages/Clients/";
import IndividualClient from "../pages/IndividualClient";

import Application from "../pages/Application";
import ApplicationForm from "../pages/ApplicationForm";

import DrawdownsPage from "../pages/DrawdownsPage";
import IndividualDrawdown from "../pages/IndividualDrawdown";

import Approvals from "../pages/Approvals";
import IndividualApproval from "../pages/IndividualApproval";
import IndividualPaymentSchedule from "../pages/IndividualPaymentSchedule";

import Requirements from "../pages/Requirements/";
import IndividualRequirement from "../pages/IndividualRequirement";

import Notification from "../pages/Notification";
import IndividualNotification from "../pages/IndividualNotification";

import ProfilePage from "../pages/ProfilePage";
import ContactsPage from "../pages/ContactsPage";
import Settings from "../pages/Settings";
import BeaconPolicies from "../pages/BeaconPoliciesPage";
import CreateApplication from "../pages/CreateApplication";

import Listings from "../pages/Listings";
import IndividualListing from "../pages/IndividualListing";

export default function AppRoutes() {
    // TODO: remove agentId from the App Routes
    const [agentId, setAgentId] = useState(null)
    useEffect(() => {
        const isLoggedIn = AuthService.isLoggedIn();
        const authGuard = AuthService.authGuard();
        if (!authGuard)
            return;

        if (isLoggedIn)
            setAgentId(AuthService.getCurrentUser().agent_id);
    }, []);

    if (!AuthService.authGuard()) return;
    else if (AuthService.isLoggedIn() && !agentId) return;

    return (
        <Router>
            <Routes>

                <Route path="/" element={<LoginPage />} />

                {/* Registration or Request Access */}
                <Route path="/register" element={<Registration />} />
                {/* <Route path='/JoinUs' element={<JoinUs />} /> */}
                <Route path='/joinus' element={<JoinUs />} />

                <Route path="/passwordreset" element={<PasswordReset />} />
                <Route path="/regenerate-registration-link" element={<RegistrationRegenerate />} />
                <Route path="/passwordreset/verify" element={<PasswordResetVerify />} />

                {/* Clients */}
                <Route path='/clients' element={<MainLayout renderContent={<Client agentId={agentId} />} agentId={agentId} />} />
                <Route path='/clients/:id' element={<MainLayout renderContent={<IndividualClient agentId={agentId} />} agentId={agentId} />} />
                <Route
                    path="/clients/:id/contacts/:contactId"
                    element={
                        <MainLayout
                            renderContent={<ContactsPage entity="client" />}
                            agentId={agentId}
                            bgColor="transparent"
                        />
                    }
                />

                {/* Application */}
                <Route path='/applications/' element={<MainLayout renderContent={<Application agentId={agentId} />} agentId={agentId} />} />

                <Route
                    path="/applications/:id"
                    element={
                        <MainLayout
                            renderContent={<ApplicationForm />}
                            agentId={agentId}
                            bgColor="tranparent"
                        />
                    }
                />

                <Route
                    path="/applications/new"
                    element={
                        <MainLayout
                            renderContent={<CreateApplication />}
                            agentId={agentId}
                            bgColor="tranparent"
                        />
                    }
                />

                <Route
                    path="/applications/draft/:id"
                    element={
                        <MainLayout
                            renderContent={<CreateApplication />}
                            agentId={agentId}
                            bgColor="tranparent"
                        />
                    }
                />

                {/* Drawdowns */}
                <Route
                    path="/drawdowns"
                    element={
                        <MainLayout
                            renderContent={<DrawdownsPage />}
                            agentId={agentId}
                            bgColor="tranparent"
                        />
                    }
                />

                <Route
                    path="/drawdowns/:id"
                    element={
                        <MainLayout
                            renderContent={<IndividualDrawdown />}
                            agentId={agentId}
                            bgColor="tranparent"
                        />
                    }
                />

                {/* Approval */}
                <Route path='/approvals' element={<MainLayout renderContent={<Approvals agentId={agentId} />} agentId={agentId} />} />
                <Route path='/approvals/:id' element={<MainLayout renderContent={<IndividualApproval />} agentId={agentId} />} />
                <Route path='/approvals/:id/paymentschedules/:paymentScheduleId' element={<MainLayout renderContent={<IndividualPaymentSchedule />} agentId={agentId} />} />



                {/* Requirements */}
                <Route
                    path="/requirements"
                    element={
                        <MainLayout
                            renderContent={<Requirements />}
                            agentId={agentId}
                            bgColor="tranparent"
                        />
                    }
                />

                <Route
                    path="/requirements/:id"
                    element={
                        <MainLayout
                            renderContent={<IndividualRequirement />}
                            agentId={agentId}
                            bgColor="tranparent"
                        />
                    }
                />

                {/* Profile */}
                <Route
                    path="/profile"
                    element={
                        <MainLayout
                            renderContent={
                                <ProfilePage
                                    agentId={agentId}
                                />
                            }
                            agentId={agentId}
                            bgColor="tranparent"
                        />
                    }
                />

                <Route
                    path="/profile/:id/contacts/:contactId"
                    element={
                        <MainLayout
                            renderContent={<ContactsPage entity="agent" />}
                            agentId={agentId}
                            bgColor="transparent"
                        />
                    }
                />

                {/* Notifications */}
                <Route
                    path="/notifications"
                    element={
                        <MainLayout
                            renderContent={
                                <Notification />
                            }
                            agentId={agentId}
                            bgColor="tranparent"
                        />
                    }
                />

                {/* Individual Notification */}
                <Route
                    path="/notifications/:ap_notificationId"
                    element={
                        <MainLayout
                            renderContent={
                                <IndividualNotification />
                            }
                            agentId={agentId}
                            bgColor="tranparent"
                            bgImage={true}
                        />
                    }
                />

                <Route path='/settings' element={<MainLayout renderContent={<Settings />} agentId={agentId} />} />

                <Route path='/resources' element={<MainLayout renderContent={<Resources />} bgColor="transparent" />} />
                <Route path='/resources/:id/:type' element={<MainLayout renderContent={<IndividualResource />} bgColor="transparent" />} />
                <Route path="/resources/beaconpolicies" element={<MainLayout renderContent={<BeaconPolicies />} bgColor="transparent" />} />

                <Route path='/listings' element={<MainLayout renderContent={<Listings />} bgColor="transparent" />} />
                <Route path='/listings/:id' element={<MainLayout renderContent={<IndividualListing />} bgColor="transparent" />} />

                {/* Dashboard*/}
                <Route
                    path="/home"
                    element={
                        <MainLayout
                            renderContent={<HomePage />}
                            agentId={agentId}
                            bgColor="tranparent"
                            dashboard={true}
                        />
                    }
                />

                <Route path="*" element={<Error404 />} />
            </Routes>
        </Router>
    );
}