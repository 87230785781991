import { theme } from 'antd';
import Icon from '@ant-design/icons';
import styles from './index.module.css';

function Kebab({isAppOpen}) {
    return (
        <svg width="8" height="26" viewBox="0 0 8 26" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path className={`${styles["Kebab"]} ${isAppOpen ? styles.disabled : ""}`}  d="M0.874999 12.7939C0.874999 13.6227 1.20424 14.4176 1.79029 15.0037C2.37634 15.5897 3.1712 15.9189 4 15.9189C4.8288 15.9189 5.62366 15.5897 6.20971 15.0037C6.79576 14.4176 7.125 13.6227 7.125 12.7939C7.125 11.9651 6.79576 11.1703 6.20971 10.5842C5.62366 9.99819 4.8288 9.66895 4 9.66895C3.1712 9.66895 2.37634 9.99819 1.79029 10.5842C1.20424 11.1703 0.874999 11.9651 0.874999 12.7939ZM0.875 3.41895C0.875 4.24775 1.20424 5.0426 1.79029 5.62865C2.37634 6.21471 3.1712 6.54395 4 6.54395C4.8288 6.54395 5.62366 6.21471 6.20971 5.62865C6.79576 5.0426 7.125 4.24775 7.125 3.41895C7.125 2.59014 6.79576 1.79529 6.20971 1.20924C5.62366 0.623185 4.8288 0.293945 4 0.293945C3.1712 0.293945 2.37634 0.623185 1.79029 1.20924C1.20424 1.79529 0.875 2.59014 0.875 3.41895ZM0.874999 22.1689C0.874999 22.9977 1.20424 23.7926 1.79029 24.3787C2.37634 24.9647 3.1712 25.2939 4 25.2939C4.8288 25.2939 5.62366 24.9647 6.20971 24.3787C6.79576 23.7926 7.125 22.9977 7.125 22.1689C7.125 21.3401 6.79576 20.5453 6.20971 19.9592C5.62366 19.3732 4.8288 19.0439 4 19.0439C3.1712 19.0439 2.37634 19.3732 1.79029 19.9592C1.20424 20.5453 0.874999 21.3401 0.874999 22.1689Z" />
        </svg>
    );
}

export default function KebabIcon({isAppOpen}) {
    const { token: { ap_userPrimaryColor, ap_userHighlightColor } } = theme.useToken();

    const themeColorVariables = {
        '--userPrimaryColor': ap_userPrimaryColor,
        '--userHighlightColor': ap_userHighlightColor,
    };

    return <Icon component={() => <Kebab isAppOpen={isAppOpen}/>} style={themeColorVariables} />
}