import { useEffect, useState } from "react";
import { theme } from "antd";

import ApiService from "../../api";
import ProgressBar from "../ProgressBar";
import CloseIcon from "../../assets/images/icons/CloseIcon";
import FileTypeIcons from "../../assets/images/icons/FileTypeIcons";
import utils from "../../utils";

import styles from "./index.module.css";

export default function UploadedFileList({ fileList, setFileList }) {

    const { token: { themeFontColor } } = theme.useToken();

    const [updatedList, setUpdatedList] = useState([]);

    useEffect(() => {
        if (!fileList) return;

        ApiService.fetchFileDetails(fileList).then(response => {
            setUpdatedList(response)
        });

    }, [fileList]);

    const handleDelete = (fileUrl) => {
        setFileList(fileList.filter(item => item.url !== fileUrl))
    }

    return (

        <>
            {(updatedList && updatedList.length > 0) && updatedList.map((file, index) => (

                <div key={file.fileUrl} className={styles["listContainer"]}>

                    <div className={styles["listContainerGrid"]}>

                        {/* Icon */}
                        <div className={styles["listIcon"]}>
                            {FileTypeIcons(file.fileName.split('.')[1], themeFontColor)}
                        </div>

                        <div>

                            <div className={styles["listDetails"]}>

                                <div style={{ textAlign: 'left' }}>
                                    <a
                                        href={utils.FormBlobFileUrl(file.fileUrl)}
                                        className={styles["fileName"]}
                                    >{file.fileName}</a>

                                </div>

                                <div
                                    style={{ textAlign: 'right' }}
                                    onClick={() => handleDelete(file.fileUrl)}
                                >
                                    <CloseIcon />
                                </div>

                            </div>

                            <div style={{ width: '100%' }}>

                                <ProgressBar
                                    size={Number(file.fileSize.split(' ')[0])}
                                    measure={file.fileSize.split(' ')[1]}
                                    updated={!!(file.updated)}
                                />

                            </div>

                        </div>

                    </div>

                </div>

            ))}

        </>

    )

}