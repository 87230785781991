import { useEffect, useState } from "react";
import { Alert, Button, Drawer, Spin, notification, theme } from "antd";
import { EditOutlined, ExclamationCircleFilled } from "@ant-design/icons";

import ApiService from "../../api";
import AssetConstants from "./constants";
import InputLayout from "../InputLayout";

import utils from "../../utils";

import HasError from "../../utils/HasErrors";
import validate from "../../utils/Validations";
import NotificationService from "../../services/notification.service";

export default function NewEditAssets({ assetData, assets, setAssets, assetIndex, icon, allowEdit = true, setUnsaved }) {
    const { token: { userPrimaryColor }, } = theme.useToken();

    // Tracks the state of the 'Edit Asset' Button
    const [invokeFieldValidaton, setInvokeFieldValidation] = useState(false);

    // Default values of Form Inputs
    const [formData, setFormData] = useState(AssetConstants.generateInitialData(assets[assetIndex] || assetData));
    const [oldData, setOldData] = useState(assets[assetIndex] || assetData);

    // Track state for trun Loading Screen on/off
    const [loading, setLoading] = useState(false);

    const [refreshVendor, setRefreshVendor] = useState(0);

    // Tracks state for opening/closing Create Asset Form
    const [open, setOpen] = useState(false);

    // Handle Update Notifications
    const [notifApi, contextHolder] = notification.useNotification();
    const openNotification = (type, message, description) => {
        notifApi[type]({
            message: message,
            description: description,
            placement: 'bottomRight',
        });
    }

    // Asset Type Event Handler
    const handleAssetType = (assetType) => {
        setFormData({
            ...formData,
            ap_assetType: {
                ...formData.ap_assetType,
                value: assetType,
                error: invokeFieldValidaton ? validate.ValidateRequiredField(assetType).errorMessage : '',
            },
            ap_truckBrand: {
                ...formData.ap_truckBrand,
                value: '',
                error: (invokeFieldValidaton && (assetType === 'Dump Truck' || assetType === 'Trucks')) ? 'Required' : '',
                hidden: (assetType !== 'Dump Truck' && assetType !== 'Trucks'),
                required: false,
            },
            ap_trailerBrand: {
                ...formData.ap_trailerBrand,
                value: '',
                error: (invokeFieldValidaton && (assetType === 'Reefers' || assetType === 'Trailers')) ? 'Required' : '',
                hidden: (assetType !== 'Reefers' && assetType !== 'Trailers'),
                required: false,
            },
            ap_brand: {
                ...formData.ap_brand,
                value: '',
                error: (invokeFieldValidaton && (assetType === 'Construction Equipment' || assetType === 'Other')) ? 'Required' : '',
                hidden: (assetType !== 'Construction Equipment' && assetType !== 'Other'),
                required: false,
            },
            ap_purpose: {
                ...formData.ap_purpose,
                value: formData.ap_purpose.value || '',
                error: (invokeFieldValidaton && (assetType !== "Amount (PAL)" && assetType !== "Amount (Working Capital)") && validate.ValidateRequiredField(formData.ap_purpose.value).errorMessage),
                required: assetType !== "Amount (PAL)" && assetType !== "Amount (Working Capital)",
            },
            ap_vin: {
                ...formData.ap_vin,
                hidden: assetType === "Other"
            },
            ap_serialNum: {
                ...formData.ap_serialNum,
                hidden: assetType !== 'Other' && assetType !== 'Construction Equipment'
            },
            ap_year: {
                ...formData.ap_year,
                hidden: (assetType === "Other")
            },
            ap_kms: {
                ...formData.ap_kms,
                hidden: (assetType === "Other")
            },
            ap_platingaddress: {
                ...formData.ap_platingaddress,
                hidden: (assetType === "Other")
            },
            ap_platingprovince: {
                ...formData.ap_platingprovince,
                hidden: (assetType === "Other")
            },
            ap_yardaddress: {
                ...formData.ap_yardaddress,
                hidden: (assetType === "Other")
            }
        });
    }

    // Brand Event Handler
    const handleBrand = (e) => {
        setFormData({
            ...formData,
            ap_brand: {
                ...formData.ap_brand,
                value: e.target.value,
                error: '',
            }
        })
    }

    // Truck Brand Event Hanlder
    const handleTruckBrand = (truckBrand) => {
        setFormData({
            ...formData,
            ap_truckBrand: {
                ...formData.ap_truckBrand,
                value: truckBrand,
                error: '',
            },
            ap_brand: {
                ...formData.ap_brand,
                error: '',
                hidden: truckBrand !== 'Other',
                required: truckBrand === 'Other',
            }
        })
    }

    // Trailer Brand Event Handler
    const handleTrailerBrand = (trailerBrand) => {
        setFormData({
            ...formData,
            ap_trailerBrand: {
                ...formData.ap_trailerBrand,
                value: trailerBrand,
                error: '',
            },
            ap_brand: {
                ...formData.ap_brand,
                error: '',
                hidden: trailerBrand !== 'Other',
                required: trailerBrand === 'Other',
            }
        })
    }

    const handlePurpose = (purpose) => {
        setFormData({
            ...formData,
            ap_purpose: {
                ...formData.ap_purpose,
                value: purpose,
                error: '',
            },
            ap_purposeOther: {
                ...formData.ap_purposeOther,
                value: purpose !== 'Other' ? '' : formData.ap_purposeOther.value,
                hidden: purpose !== 'Other',
                required: purpose === 'Other',
            }
        });
    }

    const handlePurposeOther = (e) => {
        setFormData({
            ...formData,
            ap_purposeOther: {
                ...formData.ap_purposeOther,
                value: e.target.value,
                error: formData.ap_purposeOther.required ? validate.ValidateRequiredField(e.target.value).errorMessage : '',
            }
        });
    }

    // Model Event Hanlder
    const handleModel = (e) => {
        setFormData({
            ...formData,
            ap_model: {
                ...formData.ap_model,
                value: e.target.value,
                error: (invokeFieldValidaton && formData.ap_model.required) ? validate.ValidateRequiredField(e.target.value).errorMessage : '',
            }
        })
    }

    // Year Event Handler
    const handleYear = (e) => {
        setFormData({
            ...formData,
            ap_year: {
                ...formData.ap_year,
                value: utils.ValidateYear(String(e.target.value)).value,
                error: invokeFieldValidaton ? utils.ValidateYear(String(e.target.value)).error : '',
            }
        })
    }

    // VIN Hanlder
    const handleVIN = (e) => {
        setFormData({
            ...formData,
            ap_vin: {
                ...formData.ap_vin,
                value: validate.ValidateVIN(e.target.value).value,
                error: e.target.value ? validate.ValidateVIN(e.target.value).error : '',
            }
        })
    }

    // Serial Number Event Handler
    const handleSerialNum = (e) => {
        setFormData({
            ...formData,
            ap_serialNum: {
                ...formData.ap_serialNum,
                value: validate.ValidateSerialNumber(e.target.value).value,
                error: validate.ValidateSerialNumber(e.target.value).error,
            }
        });
    }

    // KMs Event Handler
    const handleKms = (e) => {
        setFormData({
            ...formData,
            ap_kms: {
                ...formData.ap_kms,
                value: validate.ValidateKMS(e.target.value).value,
                error: e.target.value ? validate.ValidateKMS(e.target.value).error : '',
            }
        })
    }

    // Price Event Handler
    const handlePrice = (e) => {
        setFormData({
            ...formData,
            ap_price: {
                ...formData.ap_price,
                value: validate.ValidateCurrency(e.target.value).value,
                error: invokeFieldValidaton ? validate.ValidateCurrency(e.target.value).error : '',
            }
        });
    }

    // Vendor Event Hanlder
    const handleVendor = (vendor) => {
        setFormData({
            ...formData,
            ap_vendorId: {
                ...formData.ap_vendorId,
                value: vendor,
            }
        });
    }

    // Province or State Event Handlers
    const handleProvinceStates = (province) => {
        setFormData({
            ...formData,
            ap_platingprovince: {
                ...formData.ap_platingprovince,
                value: province,
            }
        });
    }

    // Plating Address Event Handler
    const handlePlatingAddress = (e) => {
        setFormData({
            ...formData,
            ap_platingaddress: {
                ...formData.ap_platingaddress,
                value: e.target.value,
            }
        });
    }

    // Yard Address Event Handler
    const handleYardAddress = (e) => {
        setFormData({
            ...formData,
            ap_yardaddress: {
                ...formData.ap_yardaddress,
                value: e.target.value,
            }
        });
    }

    // Handle Adding a new Vendor
    const handleNewVendor = (vendorName) => {
        if (vendorName) {
            setLoading(true);
            ApiService.createVendor({ ap_name: vendorName }).then((resp) => {
                if (resp?.error) {
                    openNotification('error', 'Error', resp.error);
                    return;
                }

                const tempArr = [{ label: resp?.data?.ap_name, value: resp?.data?._id }]
                setFormData({
                    ...formData,
                    ap_vendorId: {
                        ...formData.ap_vendorId,
                        dropdownContent: [...formData.ap_vendorId.dropdownContent, ...tempArr],
                        value: resp?.data?._id,
                    }
                });
                setRefreshVendor(prevRefreshVendor => prevRefreshVendor + 1);
                setTimeout(() => setLoading(false), 2500);
            });
        }
    }

    // Fetch Vendor List
    useEffect(() => {
        setLoading(true);
        ApiService.fetchVendorsList().then((data) => {
            setFormData({
                ...formData,
                ap_vendorId: {
                    ...formData.ap_vendorId,
                    dropdownContent: data,
                }
            });
        });
        setLoading(false);
    }, [open, refreshVendor]);

    const openEditAsset = (assetIndex) => {
        setOpen(prevOpen => !prevOpen);
        setInvokeFieldValidation(false);
        setFormData(AssetConstants.generateInitialData(assets[assetIndex]));
    }

    const updateAsset = () => {
        setInvokeFieldValidation(true);

        const tempData = {
            ...formData,
            ap_assetType: {
                ...formData.ap_assetType,
                error: validate.ValidateRequiredField(formData.ap_assetType.value).errorMessage,
            },
            ap_truckBrand: {
                ...formData.ap_truckBrand,
                error: '',
            },
            ap_trailerBrand: {
                ...formData.ap_trailerBrand,
                error: '',
            },
            ap_brand: {
                ...formData.ap_brand,
                error: formData.ap_brand.required ? validate.ValidateRequiredField(formData.ap_brand.value).errorMessage : '',
            },
            ap_purpose: {
                ...formData.ap_purpose,
                error: validate.ValidateRequiredField(formData.ap_purpose.value).errorMessage,
            },
            ap_purposeOther: {
                ...formData.ap_purposeOther,
                error: formData.ap_purposeOther.required ? validate.ValidateRequiredField(formData.ap_purposeOther.value).errorMessage : '',
            },
            ap_model: {
                ...formData.ap_model,
                error: '',
            },
            ap_year: {
                ...formData.ap_year,
                error: utils.ValidateYear(String(formData.ap_year.value)).error,
            },
            ap_vin: {
                ...formData.ap_vin,
                error: formData.ap_vin.required ? validate.ValidateVIN(formData.ap_vin.value).error : '',
            },
            ap_serialNum: {
                ...formData.ap_serialNum,
                error: formData.ap_serialNum.required ? validate.ValidateSerialNumber(formData.ap_serialNum.value).error : '',
            },
            ap_kms: {
                ...formData.ap_kms,
                error: formData.ap_kms.required ? validate.ValidateKMS(formData.ap_kms.value).error : '',
            },
            ap_price: {
                ...formData.ap_price,
                error: validate.ValidateCurrency(String(formData.ap_price.value)).error,
            },
            ap_vendorId: {
                ...formData.ap_vendorId,
                error: '',
            },
            ap_platingprovince: {
                ...formData.ap_platingprovince,
                error: '',
            },
            ap_platingaddress: {
                ...formData.ap_platingaddress,
                error: '',
            },
            ap_yardaddress: {
                ...formData.ap_yardaddress,
                error: '',
            }
        }
        setFormData(tempData);

        if (!HasError(tempData)) {

            const make = (
                ((formData?.ap_truckBrand?.value && formData?.ap_truckBrand?.value !== "Other") ? formData?.ap_truckBrand?.value : '') ||
                ((formData?.ap_trailerBrand?.value && formData?.ap_trailerBrand?.value !== "Other") ? formData?.ap_trailerBrand?.value : '') ||
                formData?.ap_brand?.value || ''
            );

            const name = [
                formData.ap_year.value,
                make,
                formData.ap_model.value,
                formData.ap_assetType.value
            ];

            const newData = {
                ...oldData,
                ap_name: name.filter(item => item !== "").join(" "),
                ap_type: formData.ap_assetType.value,
                ap_brand: formData.ap_brand.value,
                ap_purpose: formData.ap_purpose.value,
                ap_purposeOther: formData.ap_purposeOther.value,
                ap_make: make,
                ap_model: formData.ap_model.value,
                ap_year: formData.ap_year.value,
                ap_vin: formData.ap_vin.value,
                ap_serialNum: formData.ap_serialNum.value,
                ap_vinSerial: formData.ap_vin.value ? formData.ap_vin.value : formData.ap_serialNum.value,
                ap_kms: formData.ap_kms.value,
                ap_price: utils.convertToDBCurrency(formData?.ap_price?.value),
                ap_platingaddress: formData.ap_platingaddress.value,
                ap_yardaddress: formData.ap_yardaddress.value,
            }

            if (formData?.ap_vendorId?.value) {
                newData.ap_vendorId = formData.ap_vendorId.value;
            }

            if (!!formData.ap_truckBrand.value) {
                newData.ap_truckBrand = formData.ap_truckBrand.value;
            }

            if (!!formData.ap_trailerBrand.value) {
                newData.ap_trailerBrand = formData.ap_trailerBrand.value;
            }

            if (!!formData.ap_platingprovince.value) {
                newData.ap_platingprovince = formData.ap_platingprovince.value;
            }

            if (!utils.CheckIdenticalObjects(oldData, newData)?.areEqual)
                newData.ap_edited = true;

            const tempData = [...assets];
            tempData[assetIndex] = newData;
            setAssets(tempData);
            openEditAsset(assetIndex);
            if (setUnsaved)
                setUnsaved(true);
        } else openNotification('error', 'Error', 'Please resolve the errors and try again!');
    }

    // Attach Event Handlers
    const formFields = {
        ...formData,
        ap_assetType: {
            ...formData.ap_assetType,
            eventHandler: handleAssetType,
        },
        ap_truckBrand: {
            ...formData.ap_truckBrand,
            eventHandler: handleTruckBrand,
        },
        ap_trailerBrand: {
            ...formData.ap_trailerBrand,
            eventHandler: handleTrailerBrand,
        },
        ap_brand: {
            ...formData.ap_brand,
            eventHandler: handleBrand,
        },
        ap_model: {
            ...formData.ap_model,
            eventHandler: handleModel,
        },
        ap_purpose: {
            ...formData.ap_purpose,
            eventHandler: handlePurpose,
        },
        ap_purposeOther: {
            ...formData.ap_purposeOther,
            eventHandler: handlePurposeOther,
        },
        ap_year: {
            ...formData.ap_year,
            eventHandler: handleYear,
        },
        ap_vin: {
            ...formData.ap_vin,
            eventHandler: handleVIN,
        },
        ap_serialNum: {
            ...formData.ap_serialNum,
            eventHandler: handleSerialNum,
        },
        ap_kms: {
            ...formData.ap_kms,
            eventHandler: handleKms,
        },
        ap_price: {
            ...formData.ap_price,
            eventHandler: handlePrice,
        },
        ap_vendorId: {
            ...formData.ap_vendorId,
            eventHandler: handleVendor,
            addNew: handleNewVendor,
        },
        ap_platingprovince: {
            ...formData.ap_platingprovince,
            eventHandler: handleProvinceStates,
        },
        ap_platingaddress: {
            ...formData.ap_platingaddress,
            eventHandler: handlePlatingAddress,
        },
        ap_yardaddress: {
            ...formData.ap_yardaddress,
            eventHandler: handleYardAddress,
        },
    }

    const editEventHandler = () => {
        const assetStatus = assets[assetIndex]?.ap_assetStatus;
        const { contactDetails } = utils.IndividualAssetManagement(assetStatus);
        const label = contactDetails?.label;
        const contact = contactDetails?.contact;
        if (allowEdit)
            openEditAsset(assetIndex)
        else NotificationService.warning(<>This Asset is already {assetStatus}.<br />Please contact the <a href={`mailto:${contact}`}>{label} Team</a> to make changes</>, null, false)
    }

    return (
        <>
            {/* {icon} */}
            {icon ?
                <div onClick={editEventHandler}>{icon}</div> :
                <EditOutlined
                    style={{ color: 'orange', fontSize: 18, paddingRight: 10 }}
                    onClick={editEventHandler}
                />
            }

            <Drawer
                title="Edit Asset"
                open={open}
                onClose={() => openEditAsset(assetIndex)}
                getContainer=".AppTheme"
            >
                <Spin spinning={loading} tip="Loading..." size="large">

                    <div style={{ margin: "0 0 20px" }}>
                        <Alert
                            description={
                                <div style={{ display: 'grid', gridTemplateColumns: '20px auto', textAlign: 'justify', fontSize: '14px', gap: '10px' }}>
                                    <div><ExclamationCircleFilled style={{ color: '#D5B60A', fontSize: 'large', paddingTop: '4px' }} /></div>
                                    <div>
                                        <b>Note: </b>
                                        Please modify asset information only in case you've made an input error, like if you made a mistake in the VIN. For changes to the actual physical asset on this deal (like changing a truck to a trailer), please remove the old asset and add a new one in it's place instead.
                                    </div>
                                </div>
                            }
                            type="warning"
                        />
                    </div>

                    <InputLayout
                        data={formFields}
                        layout="vertical"
                        setData={setFormData}
                    />

                    <div style={{ display: 'flex', justifyContent: 'center' }}>
                        <Button
                            type="primary"
                            style={{ backgroundColor: userPrimaryColor, display: 'block', width: 'fit-content', }}
                            onClick={updateAsset}
                        >Save</Button>
                    </div>
                </Spin>

            </Drawer>

            {contextHolder}
        </>
    );
}