import { Progress, theme } from "antd";
import { useEffect, useState } from "react";
import styles from "./index.module.css";
import "../../assets/styles/fonts.css";

export default function ProgressBar({ size, measure, updated }) {
    const { token: { ap_userSecondaryColor }, } = theme.useToken();
    const [progress, setProgress] = useState(0);
    const [currentSize, setCurrentSize] = useState(0);
    const [showProgressBar, setShowProgressBar] = useState(!updated);

    useEffect(() => {

        const updateProgress = () => {
            const duration = 3000;
            const interval = 30;
            const steps = duration / interval;
            const increment = 100 / steps;
            const sizeIncrement = size / steps;

            let currentValue = 0;
            let currentSizeValue = 0;

            const timer = setInterval(() => {
                currentValue += increment;
                currentSizeValue += sizeIncrement;

                setProgress(Math.min(currentValue, 100));
                setCurrentSize(Math.min(currentSizeValue, size));

                if (currentValue >= 100) {
                    clearInterval(timer);
                    setInterval(() => setShowProgressBar(false), 2000);
                }

            }, interval);

        };

        updateProgress();
    }, [size]);

    return (
        <div className={styles["smooth-progress-bar"]}>
            {showProgressBar ?
                <>
                    <Progress
                        percent={progress}
                        showInfo={true}
                        strokeColor={ap_userSecondaryColor}
                    />
                    <div className={`${styles["current-size"]} Font_UltraLight`}>
                        {currentSize.toFixed(2)} {measure} of {size.toFixed(2)} {measure}
                    </div>
                </> :
                <div className={`${styles["current-size"]} Font_UltraLight`}>
                    {size.toFixed(2)} {measure}
                </div>
            }
        </div>
    );

}