// Importing necessary modules
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { ConfigProvider, Layout, Popover, theme } from "antd";

// Importing Authentication Service
import AuthService from "../../../services/auth.service";

// Importing components
import HeaderListItem from "../../../components/HeaderListItem";
import HeaderProfileIcon from "../../../assets/images/icons/HeaderProfileIcon";

// Importing Icons
import ProfileListItem from "../../../assets/images/icons/ProfileListItem";
import SettingsListItem from "../../../assets/images/icons/SettingsListIcon";
import LogOutListItem from "../../../assets/images/icons/LogOutListItem";

// Importing CSS Styles
import styles from "./index.module.css";

// Defining the HeaderProfile functional component
export default function HeaderProfile() {

    // Accessing theme tokens for colors
    const { token: { ap_userPrimaryColor, themePrimaryColor, themeFontColor } } = theme.useToken();
    const themeColorVariables = {
        '--userPrimaryColor': ap_userPrimaryColor,
        '--themePrimaryColor': themePrimaryColor,
        '--themeFontColor': themeFontColor
    }

    // React Router navigation function
    const navigate = useNavigate();

    // List of sections in the profile dropdown
    const profileList = ["profile", "settings"];

    // Extracting the first part of the current URL path
    const pathName = window.location.pathname.split('/')[1];

    // State variables to manage popover visibility and hover states
    const [open, setOpen] = useState(false);
    const handleOpenChange = () => setOpen(!open);

    // List Items Icon States
    const [profileHovered, setProfileHovered] = useState(pathName === 'profile');
    const [settingsHovered, setSettingsHovered] = useState(pathName === 'settings');
    const [logOutHovered, setLogOutHovered] = useState(false);

    // Effect to update hover states based on the current URL path
    useEffect(() => {
        setProfileHovered(pathName === 'profile');
        setSettingsHovered(pathName === 'settings');
    }, [pathName]);

    // Function to handle logout
    const handleLogOut = () => {
        AuthService.logout();   // Call the logout function from AuthService
        navigate('/');          // Navigate to the home page after logout
    }

    // Array of objects representing each section in the dropdown
    const lists = [
        {
            hovered: profileHovered,
            onMouseEnter: () => setProfileHovered(true),
            onMouseLeave: () => setProfileHovered(pathName === 'profile'),
            onClick: () => navigate('/profile'),
            icon: <ProfileListItem hovered={profileHovered} />,
            iconPosition: 'start',
            label: 'Profile'
        },
        {
            hovered: settingsHovered,
            onMouseEnter: () => setSettingsHovered(true),
            onMouseLeave: () => setSettingsHovered(pathName === 'settings'),
            onClick: () => navigate('/settings'),
            icon: <SettingsListItem hovered={settingsHovered} />,
            iconPosition: 'start',
            label: 'Settings'
        },
        {
            hovered: logOutHovered,
            onMouseEnter: () => setLogOutHovered(true),
            onMouseLeave: () => setLogOutHovered(false),
            onClick: handleLogOut,
            icon: <LogOutListItem hovered={logOutHovered} />,
            iconPosition: 'start',
            label: 'Log Out'
        },
    ];

    // Content to be displayed inside the Popover
    const content = (
        <Layout style={themeColorVariables}>
            <div className={styles["Content"]}>
                {lists.map((item, index) => (
                    <HeaderListItem {...item} key={index} />
                ))}
            </div>
        </Layout>
    );

    // Return the component with Popover and ProfileIcon
    return (
        <ConfigProvider
            theme={{
                token: {
                    colorBgLayout: 'transparent',
                    boxShadowSecondary: '0px 4px 4px rgba(0, 0, 0, 0.25)'
                }
            }}
        >
            <Popover
                title={null}
                content={content}
                arrow={false}
                trigger="hover"
                open={open}
                onOpenChange={handleOpenChange}
            >
                <div className={styles["ProfileIcon"]}>
                    <HeaderProfileIcon
                        selected={profileList.includes(pathName)}
                        open={open}
                    />
                </div>
            </Popover>
        </ConfigProvider>
    );
}