import styles from './index.module.css';
import DocumentRequestResult from '../DocumentRequestResult';
import { Button, Select, Input, ConfigProvider, Table, theme } from 'antd';
import React, { useEffect, useState } from "react";
import DocumentRequirements from '../DocumentRequirements';
import utils from '../../utils';
import constants from '../../constants';
import ApiService from '../../api';
import NotificationService from '../../services/notification.service';
import TagComponent from '../../components/TagComponent';
import EditIcon from "../../assets/images/icons/EditIcon"
import SaveIcon from '../../assets/images/icons/SaveIcon';
import validate from "../../utils/Validations";
import { handleSaveRowValidation, handleFieldValidation } from "./validation";

const { TextArea } = Input;

export default {
    Step3Component: Step3Component,
    Step2Component: Step2Component,
    Step1Component: Step1Component,
}

// TODO: USE RenderInput for Rendering Columns
function RenderInput({columnName, assetData, rows, autoSize, index, editIndex, value, handleChange, options = null}){
    const notApplicableTypes = ["Amount (PAL)", "Amount (Working Capital)", "Construction Equipment"];
    const NAFields = ["ap_year", "ap_kms", "ap_platingprovince", "ap_platingaddress", "ap_yardaddress"];
    const assetType = assetData.ap_type;
    if(notApplicableTypes.includes(assetType) && NAFields.includes(columnName)){
        return <TagComponent _id={assetData._id} active={false} message={"N/A"} />
    }else if (!editIndex.includes(index)){
        return assetData[columnName];
    }else {
        return (<Input
            rows={rows}
            autoSize={autoSize}
            value={value}
            status={handleFieldValidation(assetData, columnName)? 'error' : ''}
            onChange={(e) => {
                handleChange(e.target.value, index)
            }}
        />)
    }
}

// step 1 component rendering data
function AssetFields(assets, setAssets, setRefresh, assetSaved, isYardAddressRequired, setIsAssetSaved) {

    const [editIndex, setEditIndex] = useState([]);
    const [vendors, setVendors] = useState([]);

    
    // // construct vendors array
    React.useEffect(() => {
       if(editIndex.length){
            setIsAssetSaved(false);
       }else{
            setIsAssetSaved(true);
       }
    }, [editIndex]);

    const notApplicableTypes = ["Amount (PAL)", "Amount (Working Capital)", "Construction Equipment"];

    // construct vendors array
    React.useEffect(() => {
        ApiService.fetchVendorsList().then((response) => {
            setVendors(response);
        })
    }, []);

    // update Plating Address to ASSET
    const handlePlatingAddress = (value, index) => {
        let tempArr = [];
        tempArr = [...assets];
        const data = {
            ...tempArr[index],
            ap_platingaddress: value
        }
        tempArr[index] = data;
        setAssets(tempArr);
    }

    // update Yard Address to ASSET
    const handleYardAddress = (value, index) => {
        setAssets(prevAssets => {
            let tempArr = [...prevAssets];
            const data = {
                ...tempArr[index],
                ap_yardaddress: value
            }
            tempArr[index] = data;
            return tempArr;
        });
    }

    // update asset model to ASSET
    const handleAssetModel = (value, index) => {
        setAssets(prevAssets => {
            let tempArr = [...prevAssets];
            const data = {
                ...tempArr[index],
                ap_model: value
            }
            tempArr[index] = data;
            return tempArr;
        });
    }

    // update asset year to ASSET
    const handleAssetYear = (value, index) => {
        setAssets(prevAssets => {
            let tempArr = [...prevAssets];
            const data = {
                ...tempArr[index],
                ap_year: value
            }
            tempArr[index] = data;
            return tempArr;
        });
    }

    // update Plating Province to ASSET
    const handlePlatingProvince = (value, index) => {
        if (value) {
            let tempArr = [];
            tempArr = [...assets];

            const data = {
                ...tempArr[index],
                ap_platingprovince: value
            }
            tempArr[index] = data;

            setAssets(tempArr);

            if (!editIndex.includes(index)) {
                ApiService.updateAsset(assets[index]._id, { ap_platingprovince: value }).then((response) => {
                    if (response?.success) {
                        setRefresh(prevRefresh => prevRefresh + 1);
                        NotificationService.success('Success', "Plating Province Updated");
                    } else {
                        NotificationService.error('Error', "There was an error in updating Plating Province, please try again later")
                    }
                })
            }
        }
    }

    // update Asset Type to ASSET
    const handleAssetType = (value, index) => {
        setAssets(prevAssets => {
            let tempArr = [...prevAssets];
            const data = {
                ...tempArr[index],
                ap_type: value,
                ap_make: "",
            }
            tempArr[index] = data;
            return tempArr;
        });
    }

    // update Asset Make to ASSET
    const handleAssetMake = (value, index) => {
        setAssets(prevAssets => {
            let tempArr = [...prevAssets];
            const data = {
                ...tempArr[index],
                ap_make: value
            }
            tempArr[index] = data;
            return tempArr;
        });
    }

    // update Asset VIN/Serial to ASSET
    const handleAssetVinSerial = (value, index) => {
        setAssets(prevAssets => {
            let tempArr = [...prevAssets];
            let data;

            if (prevAssets[index].ap_type === "Other" || prevAssets[index].ap_type === "Construction Equipment") {
                data = {
                    ...tempArr[index],
                    ap_serialNum: value,
                    ap_vin: null
                }
            } else {
                data = {
                    ...tempArr[index],
                    ap_vin: value,
                    ap_serialNum: null
                }
            }

            tempArr[index] = data;
            return tempArr;
        });
    }

    // update Asset KMS to ASSET
    const handleAssetKms = (value, index) => {
        setAssets(prevAssets => {
            let tempArr = [...prevAssets];
            const data = {
                ...tempArr[index],
                ap_kms: value
            }
            tempArr[index] = data;
            return tempArr;
        });
    }

    // update Asset Price to ASSET
    const handleAssetPrice = (value, index) => {
        setAssets(prevAssets => {
            let tempArr = [...prevAssets];
            const data = {
                ...tempArr[index],
                ap_price: value
            }
            tempArr[index] = data;
            return tempArr;
        });
    }

    // update Asset Vendor to ASSET
    const handleAssetVendor = (value, index) => {
        setAssets(prevAssets => {
            let tempArr = [...prevAssets];
            const data = {
                ...tempArr[index],
                ap_vendorId: value
            }
            tempArr[index] = data;
            return tempArr;
        });
    }

    // update Plating Address to ASSET
    const handlePlatingAddressUpdate = (record) => {
        if (record) {
            ApiService.updateAsset(record._id, { ap_platingaddress: record.ap_platingaddress }).then((response) => {
                if (response?.success) {
                    setRefresh(prevRefresh => prevRefresh + 1);
                    NotificationService.success('Success', "Plating Address Updated");
                } else {
                    NotificationService.error('Error', "There was an error in updating Plating Address, please try again later")
                }
            })
        }
    }

    // update Asset row in DB
    const handleAssetSave = (index, isYardAddressRequired) => {
        const assetData = assets[index];
        
        const {isError, errorMessage} = handleSaveRowValidation(assetData, isYardAddressRequired);
        if (isError) {
            NotificationService.error('Error', errorMessage || "Please fill all the fields to save the asset");
            return;
        }

        // if assets row data changed then only update
        if (assets[index] !== assetSaved[index]) {
            const updateData = {
                ...assets[index],
                ap_price: String(assets[index]?.ap_price || 0)
            }
            ApiService.updateAsset(assets[index]._id, updateData).then((response) => {
                if (response?.success) {
                    setRefresh(prevRefresh => prevRefresh + 1);
                    setEditIndex(prevIndex => prevIndex.filter((item) => item !== index));
                    NotificationService.success('Success', "Asset Updated");
                } else {
                    NotificationService.error('Error', "There was an error in updating Asset, please try again later")
                }
            })
        } else {
            setEditIndex(prevIndex => prevIndex.filter((item) => item !== index));
        }
    }

    return [
        {
            title: 'Type',
            dataIndex: 'ap_type',
            key: 'ap_type',
            width: 140,
            render: (text, record, index) => {
                // if (editIndex.includes(index))
                //     return (
                //         <div id="ppSelect">
                //             <Select
                //                 value={record?.ap_type}
                //                 status={!record?.ap_type ? 'error' : ''}
                //                 className={styles["platingProvinceRow"]}
                //                 onChange={(e) => {
                //                     handleAssetType(e, index)
                //                 }}
                //                 options={constants.ASSET_TYPES}
                //             />
                //         </div>
                //     )
                return record?.ap_type
            }
        },
        {
            title: 'Make',
            dataIndex: 'ap_make',
            key: 'ap_make',
            width: 120,
            render: (text, record, index) => {
                const isInput = (record?.ap_type === 'Construction Equipment' || record?.ap_type === 'Other');
                const isTrailer = (record?.ap_type === 'Trailers' || record?.ap_type === 'Reefers');
                if (editIndex.includes(index))
                    return <>
                        {isInput ?
                            <Input
                                rows={1}
                                autoSize={true}
                                value={record?.ap_make}
                                status={handleFieldValidation(record, "ap_make") ? 'error' : ''}
                                onChange={(e) => handleAssetMake(e.target.value, index)}
                            /> :
                            <div id="ppSelect">
                                <Select
                                    value={record?.ap_make}
                                    status={handleFieldValidation(record, "ap_make") ? 'error' : ''}
                                    className={styles["platingProvinceRow"]}
                                    onChange={(e) => handleAssetMake(e, index)}
                                    options={isTrailer ? constants.TRAILER_BRANDS : constants.TRUCK_BRANDS}
                                />
                            </div>
                        }
                    </>

                return record?.ap_make;
            }
        },
        {
            title: 'Model',
            dataIndex: 'ap_model',
            key: 'ap_model',
            width: 90,
            render: (text, record, index) => {
                if (editIndex.includes(index))
                    return (
                        <Input
                            rows={1}
                            autoSize={true}
                            value={record?.ap_model}
                            status={handleFieldValidation(record, "ap_model") ? 'error' : ''}
                            onChange={(e) => {
                                handleAssetModel(e.target.value, index)
                            }}
                        />
                    )
                return record?.ap_model
            }
        },
        {
            title: 'Year',
            key: 'ap_year',
            width: 90,
            render: (text, record, index) => {
                if (!notApplicableTypes.includes(record.ap_type)) {
                    if (editIndex.includes(index))
                        return (<Input
                            rows={1}
                            autoSize={true}
                            value={utils.ValidateYear(String(record?.ap_year)).value}
                            status={handleFieldValidation(record, 'ap_year')? 'error' : ''}
                            onChange={(e) => {
                                handleAssetYear(e.target.value, index)
                            }}
                        />)
                    return record?.ap_year
                } else return <TagComponent _id={record._id} active={false} message={"N/A"} />
            }
        },
        {
            title: 'VIN/Serial',
            key: 'ap_vinSerial',
            width: 130,
            render: (text, record, index) => {
                const isSerial = (record?.ap_type === 'Construction Equipment' || record?.ap_type === 'Other');
                if (editIndex.includes(index))
                    return (
                        <Input
                            rows={1}
                            autoSize={true}
                            value={isSerial ? validate.ValidateSerialNumber(record?.ap_serialNum).value : validate.ValidateVIN(record?.ap_vin).value}
                            status={handleFieldValidation(record, "ap_vinSerial") ? 'error' : ''}
                            onChange={(e) => {
                                handleAssetVinSerial(e.target.value, index)
                            }}
                        />
                    )
                return record?.ap_vin || record?.ap_serialNum
            }
        },
        {
            title: 'KMs/Hours',
            key: 'ap_kms',
            width: 100,
            render: (text, record, index) => {
                if (!notApplicableTypes.includes(record.ap_type)) {
                    if (editIndex.includes(index))
                        return (
                            <Input
                                rows={1}
                                autoSize={true}
                                value={validate.ValidateKMS(record?.ap_kms).value}
                                status={handleFieldValidation(record, 'ap_kms') ? 'error' : ''}
                                onChange={(e) => {
                                    handleAssetKms(e.target.value, index)
                                }}
                            />
                        )
                    return record?.ap_kms
                } else return <TagComponent _id={record._id} active={false} message={"N/A"} />
            }
        },
        {
            title: 'Price',
            key: 'ap_price',
            width: 130,
            render: (text, record, index) => {
                if (editIndex.includes(index))
                    return (
                        <Input
                            rows={1}
                            autoSize={true}
                            value={validate.ValidateCurrency(String(record?.ap_price)).value}
                            status={handleFieldValidation(record, "ap_price") ? 'error' : ''}
                            onChange={(e) => {
                                handleAssetPrice(e.target.value, index)
                            }}
                        />
                    )
                return utils.FormatCurrency(record?.ap_price);
            }
        },
        {
            title: 'Vendor',
            dataIndex: 'ap_vendorId',
            key: 'ap_vendorId',
            width: 120,
            render: (text, record, index) => {
                if (editIndex.includes(index))
                    return (
                        <div id="ppSelect">
                            <Select
                                value={record?.ap_vendorId?.ap_name || record?.ap_vendorId}
                                status={handleFieldValidation(record, "ap_vendorId") ? 'error' : ''}
                                className={styles["platingProvinceRow"]}
                                onChange={(e) => {
                                    handleAssetVendor(e, index)
                                }}
                                options={vendors}
                            />
                        </div>)
                return record?.ap_vendorId?.ap_name;
            }

        },
        {
            title: 'Plating Province',
            key: 'ap_platingprovince',
            width: 150,
            render: (text, record, index) =>
                (!notApplicableTypes.includes(record.ap_type)) ?
                    (<div id="ppSelect">
                        <Select
                            value={record?.ap_platingprovince}
                            status={handleFieldValidation(record, 'ap_platingprovince') ? 'error' : ''}
                            className={styles["platingProvinceRow"]}
                            onChange={(e) => {
                                handlePlatingProvince(e, index)
                            }}
                            options={constants.CANADIAN_PROVINCES}
                        />
                    </div>)

                    : <TagComponent _id={record._id} active={false} message={"N/A"} />
        },
        {
            title: 'Plating Address',
            key: 'ap_platingaddress',
            width: 180,
            render: (text, record, index) =>
                (!notApplicableTypes.includes(record.ap_type)) ?
                    (<TextArea
                        rows={1}
                        autoSize={true}
                        value={record?.ap_platingaddress}
                        status={''}
                        onChange={(e) => {
                            handlePlatingAddress(e.target.value, index)
                        }}
                        onBlur={() => !editIndex.includes(index) ? handlePlatingAddressUpdate(record) : null}
                    />) : <TagComponent _id={record._id} active={false} message={"N/A"} />
        },
        {
            title: 'Yard Address',
            key: 'ap_yardaddress',
            width: 150,
            render: (text, record, index) => {
                if (!notApplicableTypes.includes(record.ap_type)) {
                    if (editIndex.includes(index))
                        return (<TextArea
                            rows={1}
                            autoSize={true}
                            value={record?.ap_yardaddress}
                            status={(isYardAddressRequired && handleFieldValidation(record, 'ap_yardaddress')) ? 'error' : ''}
                            onChange={(e) => {
                                handleYardAddress(e.target.value, index)
                            }}
                        />)
                    else return record?.ap_yardaddress
                } else return <TagComponent _id={record._id} active={false} message={"N/A"} />
            }
        },
        {
            title: '',
            key: 'actions',
            width: 58,
            render: (text, record, index) => {
                if (editIndex.includes(index))
                    return <div onClick={() => handleAssetSave(index, isYardAddressRequired)}><SaveIcon /></div>;
                else return <div onClick={() => setEditIndex(prevIndex => [...prevIndex, index])}><EditIcon /></div>;
            }
        }
    ];

}

// step 3 component rendering data
function Step3Component({ showResult }) {
    return (
        <div className={styles["resultScreen"]}>
            {/* TODO: to add the logic for creation of DD in CRM, then in Beacon and get the id to pass to result screen */}
            {showResult && <DocumentRequestResult />}
        </div>
    )
}

// step 2 component rendering data
function Step2Component({ requirements, folderName, fileList, setFileList, comments, setComments, handleDocumentRequested, handleBack }) {

    // Initialize tempDoc object
    const tempDoc = {};

    // Dynamically generate entries for pre-funding requirements
    requirements?.forEach((requirement) => {
        const key = requirement.toLowerCase();
        tempDoc[key] = {
            name: `${requirement}`,
            required: false,
            hidden: false,
            resource: null,
            checked: false,
        };
    });

    // Add "Other Documents" entry at the end
    tempDoc.otherDocuments = {
        name: 'Other Documents',
        additionalInfo: '*Please specify in comments',
        required: false,
        hidden: false,
        resource: null,
        checked: false,
    };

    return (
        <>
            <div className={styles["modelDetails"]}>
                <div className={styles["modalSubTitle"]}>
                    Please upload any unsubmitted pre-funding requirements.
                </div>
                <DocumentRequirements
                    documentContainerHeader="Documents"
                    documentContainerSubHeader="The following documents are required for funding."
                    documentsList={tempDoc}
                    showDocumentsError={false}
                    folderName={folderName}
                    files={fileList}
                    setFiles={setFileList}
                    showFiles={true}
                    fileComments={comments}
                    setFileComments={setComments}
                    shouldShowBoxShadow={false}
                />
            </div>
            <div className={styles["Step2_Button"]}>
                <Button
                    onClick={handleBack}
                    className={styles["Back_Button"]}
                    type="text"
                >Back</Button>
                <Button
                    type="primary"
                    onClick={handleDocumentRequested}
                    className={styles["Skip_To_Uploading_Button"]}
                >Request Documents</Button>
            </div>
        </>
    );
}

function Step1Component({ assets, setAssets, assetSaved, isYardAddressRequired, setRefresh, updateMultiple, handleUploadMultipleBack, handleUpdateMultiple, handleSkipToUplaod, values, handlePP, handlePA, handleAddressFill, hasSelected, rowSelection, div1Ref, errors }) {
    const { token: { ap_userPrimaryColor, ap_userHighlightColor, themePrimaryColor, themeSecondaryColor, themeFontColor, themeDisabledContainer } } = theme.useToken();
    const [isAssetSaved, setIsAssetSaved] = useState(true);

    return (
        <>
            <div id="step1" style={{ minHeight: "68vh" }} ref={div1Ref}>
                <div className={styles["modelDetails"]} >
                    <div className={styles["modalSubTitle"]}>
                        Please ensure you have the plating province and address for each equipment asset.
                    </div>

                    {updateMultiple ? <div style={{ marginTop: '.5%' }}>
                        Select the asset(s) you would like to fill.
                    </div>
                        :
                        <div style={{ margin: '10px 0px' }}>
                            Also, please make sure you audit all other information and ensure it is up-to-date.
                        </div>}

                    <div className={styles["step1Table"]}>
                        <ConfigProvider
                            theme={{
                                token: {
                                    colorFillAlter: ap_userPrimaryColor,
                                    colorBgContainer: themePrimaryColor,
                                    colorFillSecondary: themePrimaryColor,
                                    colorText: themeFontColor,
                                    colorIcon: themeFontColor,
                                    colorTextPlaceholder: themeFontColor,
                                    colorTextHeading: 'white'
                                },
                            }}
                        >
                            <Table
                                rowSelection={updateMultiple ? rowSelection : null}
                                columns={AssetFields(assets, setAssets, setRefresh, assetSaved, isYardAddressRequired, setIsAssetSaved)}
                                dataSource={assets.map((asset, index) => ({ ...asset, key: index }))}
                                pagination={false}
                                scroll={{ y: 500 }}
                                minHeight={500}
                            />
                        </ConfigProvider>
                    </div>

                    {updateMultiple ?
                        <div className={styles["selectToFillHeading"]}>
                            Select the province/state for the highlighted asset(s) and input the address for the highlighted asset(s). Once you are finished, select 'Fill',
                        </div> : <></>}

                    {updateMultiple ?
                        <div id="ppInput"
                            className={`test ${styles["Fill_Container"]}`}
                        >

                            <ConfigProvider
                                theme={{
                                    token: {
                                        colorText: ap_userPrimaryColor,
                                        colorTextDisabled: ap_userPrimaryColor,
                                        colorIcon: ap_userPrimaryColor,
                                        colorPrimaryHover: ap_userPrimaryColor,
                                        colorTextQuaternary: ap_userPrimaryColor,
                                        controlOutline: ap_userHighlightColor,
                                        colorBorder: ap_userHighlightColor,
                                        colorBgContainer: themePrimaryColor,
                                        controlItemBgHover: themeSecondaryColor,
                                        colorIconHover: themeSecondaryColor,
                                        colorTextPlaceholder: themeFontColor,
                                        colorBgContainerDisabled: themeDisabledContainer,
                                    }
                                }}
                            >
                                <Select placeholder="Select Plating Province"
                                    value={values?.ap_platingprovince?.value}
                                    onChange={(e) => {
                                        handlePP(e)
                                    }}
                                    options={constants.CANADIAN_PROVINCES}
                                    disabled={!hasSelected}
                                />

                                <Input placeholder="Input Plating Address"
                                    value={values?.ap_platingaddress?.value}
                                    onChange={(e) => {
                                        handlePA(e.target.value)
                                    }}
                                    disabled={!hasSelected}
                                />

                            </ConfigProvider>

                            <Button
                                type="primary"
                                onClick={handleAddressFill}
                                className={styles["Fill_Button"]}
                                disabled={!values?.ap_platingprovince?.value || !values?.ap_platingaddress?.value}
                            >Fill</Button>
                        </div> : <></>}
                </div>

                <div className={styles["Upload_Button"]}>
                    {updateMultiple ?
                        <div className={styles["Button_Container"]}>
                            <Button
                                onClick={handleUploadMultipleBack}
                                className={styles["Back_Button"]}
                                type="text"
                            >Back</Button>
                        </div> :
                        <div className={styles["UpdateMultiple_Container"]}>
                            <Button
                                type="primary"
                                onClick={handleUpdateMultiple}
                                className={styles["Update_Multiple_Button"]}
                            >Update Multiple</Button>
                        </div>
                    }
                    <Button
                        type="primary"
                        onClick={handleSkipToUplaod}
                        className={styles["Skip_To_Uploading_Button"]}
                        disabled={errors || !isAssetSaved}
                    >Proceed to Step 2</Button>
                </div>
            </div>
        </>
    )
}