// Importing necessary dependencies from React and other modules
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

import { ConfigProvider, Popover } from "antd"

import NotificationAccordian from "../../../components/NotificationAccordian";
import HeaderNotificationIcon from "../../../assets/images/icons/HeaderNotificationIcon";
import accordianConstants from "../../../components/NotificationAccordian/constants";
import CountUnreadActiveNotifications from "../../../utils/CountUnreadNotifications";

// Importing  services
import ApiService from "../../../api";
import AuthService from "../../../services/auth.service";
import NotificationService from "../../../services/notification.service";
import WebSocketConnection from "../../../services/WebSocketConnection";
import appInsightsObj from "../../../services/app-insight-service";

// Importing CSS styles
import styles from "./index.module.css";

// Function component definition for header notifications
export default function HeaderNotification() {

    const navigate = useNavigate();

    const [open, setOpen] = useState(false);
    const handleOpenChange = () => setOpen(prevOpen => !prevOpen);

    const [agentId, setAgentId] = useState(null);
    const [refresh, setRefresh] = useState(0);

    const [notifications, setNotifications] = useState([]);
    const [filteredNotifications, setFilteredNotifications] = useState([]);
    const [unreadCount, setUnreadCount] = useState(0);

    // Extracting the first part of the current URL path
    const pathName = window.location.pathname.split('/')[1];

    // Define a function to handle the opening of a notification with the given ID.
    const handleOpenNotif = (notifId) => {
        // Data object to update the notification as read
        const data = {
            ap_isRead: true,
        };

        // Call the ApiService to update the notification with the provided ID
        ApiService.updateNotification(notifId, data).then(response => {
            // Check if the response indicates a successful update
            if (response?.success) {
                // If the update is successful, trigger a refresh of the notification list
                setRefresh(prevRefresh => prevRefresh + 1);

                // Navigate to the detailed view of the updated notification
                navigate(`/notifications/${notifId}`);
            }
        });
    }

    // Effect hook for initial setup
    useEffect(() => {
        // Checking if the user is authenticated and authorized
        const isLoggedIn = AuthService.isLoggedIn();
        const authGuard = AuthService.authGuard();

        if (!authGuard || !isLoggedIn)
            // Refreshing component if not authenticated
            setRefresh(prevRefresh => prevRefresh + 1);
        else {
            const currentUser = AuthService?.getCurrentUser();
            if (currentUser?.agent_id)
                // Setting agent ID if available
                setAgentId(currentUser?.agent_id);
            else
                // Refreshing component if agent ID is not available
                setRefresh(prevRefresh => prevRefresh + 1);
        }
    }, []);

    // Effect hook to fetch and manage notifications
    useEffect(() => {
        if (!agentId) return;
        ApiService.fetchAgentActiveNotifications(agentId).then(notifResponse => {

            if (!notifResponse?.length) return;

            // Update the notifications state with the latest records.
            setNotifications(notifResponse);

            // Calculate and update the count of unread active notifications.
            setUnreadCount(CountUnreadActiveNotifications(notifResponse));
        })
    }, [refresh, agentId]);

    useEffect(() => {
        // Check if agentId is not available, return early to avoid unnecessary processing.
        if (!agentId) return;
        // Subscribe to WebSocketConnection to receive new records.
        if (!(WebSocketConnection.isAlive) === true) return;
        WebSocketConnection.subscribe((newRecord) => {
            // For the first render, newRecord will be array of all agent notifications
            if (!(newRecord?.length)) {
                setNotifications(prevNotifications => {

                    // Check if the new record is already present in the notifications list.
                    if (prevNotifications.some(item => item?._id === newRecord?._id)) {
                        return prevNotifications.map(item => item?._id === newRecord?._id ? newRecord : item);
                    }
                    // Check if the new record has not been notified yet.
                    if (!newRecord?.ap_notified) {

                        // Generate a description for the notification.
                        const compiledData = accordianConstants.generateData(newRecord?.ap_notificationType, newRecord?.ap_data);

                        // Construct the notification description UI.
                        const description = (
                            <div className={styles["DescriptionContainer"]}>
                                <div>{compiledData?.icon}</div>
                                <div>
                                    <div className={styles["DescriptionHeader"]}>{compiledData?.headerText}</div>
                                    <div>{compiledData?.subText}</div>
                                </div>
                            </div>
                        );

                        // Open a new notification with the generated description.
                        NotificationService.open(
                            null,
                            description,
                            () => handleOpenNotif(newRecord?._id)
                        );
                        appInsightsObj.info({
                            "Log-Notification-Error": newRecord
                        })
                        // Mark the notification as notified by sending an update to the server.
                        if (newRecord?._id)
                            ApiService.updateNotification(newRecord?._id, { ap_notified: true }).then(response => {
                                if (response?.success) return;
                            });
                    }

                    // Add the new record to the notifications list, sort, and limit to 6 records.
                    const newResponse = [...prevNotifications, newRecord].sort((a, b) => new Date(b?.createdAt) - new Date(a?.createdAt));

                    return newResponse;
                });

            }
        }, agentId);
    }, [agentId, refresh]);

    useEffect(() => {
        const filteredRecords = notifications.filter(record => {
            return ((record.ap_isActive) && (record.ap_isActiveAccordian) && (!record.ap_isRead));
        });

        setFilteredNotifications(filteredRecords);

    }, [notifications, open]);

    useEffect(() => {
        // Calculate and update the count of unread active notifications.
        setUnreadCount(CountUnreadActiveNotifications(filteredNotifications));
    }, [filteredNotifications]);


    // Content to be displayed within the popover
    const content = (
        <div className={styles["Content"]}>
            <NotificationAccordian
                data={filteredNotifications}
                setOpen={setOpen}
                setRefresh={setRefresh}
            />
        </div>
    );

    // Component rendering
    return (
        <ConfigProvider
            theme={{
                token: {
                    colorBgLayout: 'transparent',
                    boxShadowSecondary: '0px 4px 4px rgba(0, 0, 0, 0.25)'
                }
            }}
        >
            <Popover
                title={null}
                content={content}
                arrow={false}
                trigger="click"
                open={open}
                onOpenChange={handleOpenChange}
            >
                <div className={styles["NotificationIcon"]}>
                    <HeaderNotificationIcon
                        open={open}
                        unreadCount={unreadCount}
                        selected={pathName === "notifications"}
                    />
                </div>
            </Popover>
        </ConfigProvider>
    );
}