import { theme } from "antd";
import Icon from '@ant-design/icons';

function ProfileIcon() {
    const { token: { ap_userPrimaryColor } } = theme.useToken();
    return (
        <svg width="20" height="23" viewBox="0 0 21 23" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M10.75 2.72222C9.57126 2.72222 8.4408 3.19047 7.6073 4.02397C6.77381 4.85746 6.30556 5.98793 6.30556 7.16667C6.30556 8.34541 6.77381 9.47587 7.6073 10.3094C8.4408 11.1429 9.57126 11.6111 10.75 11.6111C11.9287 11.6111 13.0592 11.1429 13.8927 10.3094C14.7262 9.47587 15.1944 8.34541 15.1944 7.16667C15.1944 5.98793 14.7262 4.85746 13.8927 4.02397C13.0592 3.19047 11.9287 2.72222 10.75 2.72222ZM4.08333 7.16667C4.08333 5.39856 4.78571 3.70286 6.03595 2.45262C7.2862 1.20238 8.98189 0.5 10.75 0.5C12.5181 0.5 14.2138 1.20238 15.464 2.45262C16.7143 3.70286 17.4167 5.39856 17.4167 7.16667C17.4167 8.93478 16.7143 10.6305 15.464 11.8807C14.2138 13.131 12.5181 13.8333 10.75 13.8333C8.98189 13.8333 7.2862 13.131 6.03595 11.8807C4.78571 10.6305 4.08333 8.93478 4.08333 7.16667Z" fill={ap_userPrimaryColor} />
            <path fillRule="evenodd" clipRule="evenodd" d="M1.86111 22.7222C1.56643 22.7222 1.28381 22.6052 1.07544 22.3968C0.867063 22.1884 0.75 21.9058 0.75 21.6111C0.75 20.1377 1.33532 18.7246 2.37718 17.6827C3.41905 16.6409 4.83213 16.0556 6.30556 16.0556H15.1944C16.6679 16.0556 18.0809 16.6409 19.1228 17.6827C20.1647 18.7246 20.75 20.1377 20.75 21.6111C20.75 21.9058 20.6329 22.1884 20.4246 22.3968C20.2162 22.6052 19.9336 22.7222 19.6389 22.7222H1.86111ZM3.94853 19.2541C4.57365 18.629 5.4215 18.2778 6.30556 18.2778H15.1944C16.0785 18.2778 16.9263 18.629 17.5515 19.2541C17.9444 19.647 18.2291 20.1279 18.3864 20.6505H3.11364C3.27093 20.1279 3.55562 19.647 3.94853 19.2541Z" fill={ap_userPrimaryColor} />
        </svg>
    )
}

function ProfileIconHovered() {
    const { token: { ap_userPrimaryColor } } = theme.useToken();
    return (
        <svg width="20" height="23" viewBox="0 0 21 23" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M10.75 2.72222C9.57126 2.72222 8.4408 3.19047 7.6073 4.02397C6.77381 4.85746 6.30556 5.98793 6.30556 7.16667C6.30556 8.34541 6.77381 9.47587 7.6073 10.3094C8.4408 11.1429 9.57126 11.6111 10.75 11.6111C11.9287 11.6111 13.0592 11.1429 13.8927 10.3094C14.7262 9.47587 15.1944 8.34541 15.1944 7.16667C15.1944 5.98793 14.7262 4.85746 13.8927 4.02397C13.0592 3.19047 11.9287 2.72222 10.75 2.72222ZM4.08333 7.16667C4.08333 5.39856 4.78571 3.70286 6.03595 2.45262C7.2862 1.20238 8.98189 0.5 10.75 0.5C12.5181 0.5 14.2138 1.20238 15.464 2.45262C16.7143 3.70286 17.4167 5.39856 17.4167 7.16667C17.4167 8.93478 16.7143 10.6305 15.464 11.8807C14.2138 13.131 12.5181 13.8333 10.75 13.8333C8.98189 13.8333 7.2862 13.131 6.03595 11.8807C4.78571 10.6305 4.08333 8.93478 4.08333 7.16667Z" fill={ap_userPrimaryColor} />
            <path d="M1.86111 22.7222C1.56643 22.7222 1.28381 22.6052 1.07544 22.3968C0.867063 22.1884 0.75 21.9058 0.75 21.6111C0.75 20.1377 1.33532 18.7246 2.37718 17.6827C3.41905 16.6409 4.83213 16.0556 6.30556 16.0556H15.1944C16.6679 16.0556 18.0809 16.6409 19.1228 17.6827C20.1647 18.7246 20.75 20.1377 20.75 21.6111C20.75 21.9058 20.6329 22.1884 20.4246 22.3968C20.2162 22.6052 19.9336 22.7222 19.6389 22.7222H1.86111Z" fill={ap_userPrimaryColor} />
            <path d="M10.75 2.72222C9.57126 2.72222 8.4408 3.19047 7.6073 4.02397C6.77381 4.85746 6.30556 5.98793 6.30556 7.16667C6.30556 8.34541 6.77381 9.47587 7.6073 10.3094C8.4408 11.1429 9.57126 11.6111 10.75 11.6111C11.9287 11.6111 13.0592 11.1429 13.8927 10.3094C14.7262 9.47587 15.1944 8.34541 15.1944 7.16667C15.1944 5.98793 14.7262 4.85746 13.8927 4.02397C13.0592 3.19047 11.9287 2.72222 10.75 2.72222Z" fill={ap_userPrimaryColor} />
        </svg>
    );
}

export default function ProfileListItem({ hovered }) {
    return (
        <>
            {hovered ?
                <Icon component={() => ProfileIconHovered()} /> :
                <Icon component={() => ProfileIcon()} />
            }
        </>
    )
}