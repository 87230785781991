import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { Layout, Spin, Tabs, theme } from "antd";

import AuthService from "../../services/auth.service";
import ApiService from "../../api";

import WebSocketConnection from "../../services/WebSocketConnection";
import NotificationPageList from "../../components/NotificationPageList";

import styles from "./index.module.css";
import "../../assets/styles/fonts.css";
import { CloseOutlined } from "@ant-design/icons";

export default function NotificationsLayout({ filterList, setFilterList, client }) {

    const [loader, setLoader] = useState({ loading: false, tip: '' })

    const { token: { themeFontColor } } = theme.useToken();
    const themeColorVariables = { '--themeFontColor': themeFontColor, };

    const navigate = useNavigate();
    const location = useLocation();

    const [refresh, setRefresh] = useState(0);
    const [agentId, setAgentId] = useState(null);

    const [notifications, setNotifications] = useState([]);
    const [readNotifications, setReadNotifications] = useState([]);
    const [unreadNotifications, setUnreadNotifications] = useState([]);

    const [readFiltered, setReadFiltered] = useState([]);
    const [unreadFiltered, setUnreadFiltered] = useState([]);

    const [clientList, setClientList] = useState([]);

    const [activeKey, setActiveKey] = useState("unread");
    const onTabChange = (key) => setActiveKey(key);

    useEffect(() => {
        const queryParams = new URLSearchParams(location.search);
        const readParam = queryParams.get('read');
        setActiveKey(readParam === 'true' ? 'read' : 'unread');
    }, [location]);

    useEffect(() => {
        const queryParams = new URLSearchParams(location.search);
        queryParams.set('read', activeKey === "read");
        const baseUrl = window.location.href.split('?')[0];
        const newUrl = `${baseUrl}?${queryParams.toString()}`;
        window.history.replaceState({}, '', newUrl);
    }, [activeKey]);

    // Effect hook for initial setup
    useEffect(() => {
        // Checking if the user is authenticated and authorized
        const isLoggedIn = AuthService.isLoggedIn();
        const authGuard = AuthService.authGuard();

        if (!authGuard || !isLoggedIn)
            // Refreshing component if not authenticated
            setRefresh(prevRefresh => prevRefresh + 1);
        else {
            const currentUser = AuthService?.getCurrentUser();
            if (currentUser?.agent_id)
                // Setting agent ID if available
                setAgentId(currentUser?.agent_id);
            else
                // Refreshing component if agent ID is not available
                setRefresh(prevRefresh => prevRefresh + 1);
        }
    }, []);

    // Effect hook to subscribe to Websocket notifications for the specified agent
    useEffect(() => {
        // Check if agentId is not available, return early to avoid unnecessary processing.
        if (!agentId) return;

        // Making an API Call so that the notifications starts to broadcast
        ApiService.fetchAgentActiveNotifications(agentId).then(notifResponse => {
            if (!notifResponse?.length) return;

            // Update the notifications state with the latest records.
            setNotifications(notifResponse);

        })

        if (!(WebSocketConnection.isAlive === true)) return;

        WebSocketConnection.subscribe((record) => {
            //     // For the first render, newRecord will be array of all agent notifications
            if (!(record?.length)) {
                setNotifications(prevNotifications => {
                    // Check if the new record is already present in the notifications list.
                    const isRecordPresent = prevNotifications.some(item => item?._id === record?._id);

                    if (isRecordPresent) {
                        // If the new record is already present, update it in the list.
                        const updatedNotifications = prevNotifications.map(item => item?._id === record?._id ? record : item);
                        return updatedNotifications.sort((a, b) => new Date(b?.createdAt) - new Date(a?.createdAt));
                    }

                    // If the new record is not present, add it to the top of the list.
                    const newResponse = [record, ...prevNotifications].sort((a, b) => new Date(b?.createdAt) - new Date(a?.createdAt));
                    return newResponse;
                });
            }
        }, agentId);
    }, [agentId, refresh, navigate]);

    useEffect(() => {
        // Check if notifications is not available, return early to avoid unnecessary processing.
        if (!notifications?.length) return;

        // Filter notifications based on read status.
        const readNotifs = notifications.filter(item => item?.ap_isRead);
        const unreadNotifs = notifications.filter(item => !item?.ap_isRead);

        // Update the read and unread notifications state.
        setReadNotifications(readNotifs);
        setUnreadNotifications(unreadNotifs);

    }, [notifications, refresh]);

    const tabItems = [
        {
            key: "unread",
            label: "Unread",
        },
        {
            key: "read",
            label: "Read",
        }
    ];

    const removeFilter = (index) => {
        setFilterList(prevList => {
            let temp = [...prevList];
            temp[index].checked = false;
            return temp;
        })
        // Keep an eye on this functionality which seems to be a bug from antd
        // This is to trigger the onChange event of the checkbox
        document.getElementById(filterList[index].value)?.click();
    }

    useEffect(() => {
        const filters = filterList.filter(item => item.checked).map(item => item.value);
        const readFilteredList = readNotifications.filter(item => filters.includes(item?.ap_notificationType));
        const unreadFilteredList = unreadNotifications.filter(item => filters.includes(item?.ap_notificationType));
        setReadFiltered((filters?.length > 0) ? readFilteredList : readNotifications);
        setUnreadFiltered((filters?.length > 0) ? unreadFilteredList : unreadNotifications);
    }, [notifications, readNotifications, unreadNotifications, filterList]);


    const handleMarkAllAsRead = () => {

        const markingIds = (activeKey === 'unread') ? unreadFiltered.map(item => item?._id) : [];


        markingIds.forEach(id => {
            ApiService.updateNotification(id, { ap_isRead: true }).then(response => {
                if (response?.success)
                    setRefresh(prevRefresh => prevRefresh + 1);
            });
        });
    }

    useEffect(() => {
        const queryParams = new URLSearchParams(location.search);
        const filterParam = queryParams.get('filters');
        if (filterParam) {
            const filtersArr = filterParam.split(',');
            setFilterList(prevFilterList => {
                let temp = [...prevFilterList];
                temp.map((item, index) => {
                    if (filtersArr.includes(item.value))
                        temp[index].checked = true;
                    else
                        temp[index].checked = false;
                    return item;
                });
                return temp;
            });
        }
    }, []);

    useEffect(() => {
        const filteredValues = filterList.filter(item => item.checked).map(item => item.value).join(',');

        const queryParams = new URLSearchParams(location.search);

        queryParams.delete('filters');

        if (filteredValues)
            queryParams.set('filters', filteredValues);

        const baseUrl = window.location.href.split('?')[0];
        const newUrl = `${baseUrl}?${queryParams.toString()}`;
        window.history.replaceState({}, '', newUrl);
    }, [filterList]);



    return (
        <Layout style={themeColorVariables}>

            <div style={{ display: 'flex', placeContent: 'center space-between' }}>
                <Tabs
                    defaultActiveKey="unread"
                    items={tabItems}
                    activeKey={activeKey}
                    onTabClick={onTabChange}
                />
                <div onClick={handleMarkAllAsRead} className={(activeKey === 'unread') ? ((unreadFiltered?.length > 0) ? styles["MarkAllAsRead"] : styles["MarkAllAsRead_Disabled"]) : styles["Hide"]}>Mark All As Read</div>
            </div>

            <div style={{ display: 'flex', flexWrap: 'wrap', gap: '20px', marginBottom: '20px' }}>
                {filterList.map((item, index) => {
                    if (item?.checked) {
                        return (
                            <div key={index} className={styles["TagContainer"]}>
                                <div>{item?.label}</div>
                                <div onClick={() => removeFilter(index)}><CloseOutlined className={styles["CloseIcon"]} /></div>
                            </div>
                        )
                    }
                })}
            </div>

            {/* Unread Notifications */}
            {(activeKey === "unread") && (
                <Spin spinning={loader?.loading} tip={loader?.tip}>
                    <div className={styles["TabContainer"]}>
                        <NotificationPageList
                            notifications={unreadFiltered}
                            isRead={false}
                            navigate={navigate}
                            setRefresh={setRefresh}
                            setNotifications={setNotifications}
                            setLoader={setLoader}
                        />
                    </div>
                </Spin>
            )}

            {/* Read Notifications */}
            {(activeKey === "read") && (
                <Spin spinning={loader?.loading} tip={loader?.tip}>
                    <div className={styles["TabContainer"]}>
                        <NotificationPageList
                            notifications={readFiltered}
                            isRead={true}
                            navigate={navigate}
                            setRefresh={setRefresh}
                            setNotifications={setNotifications}
                            setLoader={setLoader}
                        />
                    </div>
                </Spin>
            )}

        </Layout>
    );
}