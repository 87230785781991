import { theme } from "antd";

export default function AccessRequest() {
    const { token: { ap_userSecondaryColor }, } = theme.useToken();
    return (
        <svg width="60" height="60" viewBox="0 0 50 50" fill="none" xmlns="http://www.w3.org/2000/svg">
            <rect width="50" height="50" rx="10" fill={ap_userSecondaryColor} />
            <path fillRule="evenodd" clipRule="evenodd" d="M15.0127 6.66675C13.6833 6.66675 12.4083 7.1881 11.4682 8.11612C10.5281 9.04413 10 10.3028 10 11.6152V36.3575C10 37.6699 10.5281 38.9285 11.4682 39.8566C12.4083 40.7846 13.6833 41.3059 15.0127 41.3059H26.4277C26.0533 40.544 25.805 39.7104 25.7097 38.8317H15.0127C14.348 38.8317 13.7105 38.571 13.2405 38.107C12.7704 37.643 12.5064 37.0137 12.5064 36.3575V11.6152C12.5064 10.959 12.7704 10.3297 13.2405 9.86566C13.7105 9.40165 14.348 9.14097 15.0127 9.14097H22.5311V17.8007C22.5311 18.785 22.9272 19.729 23.6323 20.425C24.3373 21.1211 25.2936 21.5121 26.2907 21.5121H35.0636V30.6825C35.9726 30.8944 36.8188 31.2665 37.57 31.7671V21.2993C37.5698 20.3153 37.1738 19.3717 36.4691 18.6758L25.405 7.75357L25.4049 7.75347C24.7 7.05778 23.7441 6.6669 22.7474 6.66675H15.0127ZM25.0375 10.8899L33.2913 19.0378H26.2907C25.9583 19.0378 25.6396 18.9075 25.4045 18.6755C25.1695 18.4435 25.0375 18.1288 25.0375 17.8007V10.8899Z" fill="white" />
            <path fillRule="evenodd" clipRule="evenodd" d="M33.3019 44.1666C36.7409 44.1666 39.5287 41.4146 39.5287 38.0197C39.5287 34.6249 36.7409 31.8728 33.3019 31.8728C29.863 31.8728 27.0752 34.6249 27.0752 38.0197C27.0752 41.4146 29.863 44.1666 33.3019 44.1666ZM32.4147 38.9264L31.0759 37.6048C30.9618 37.4921 30.8165 37.4358 30.6401 37.4358C30.4636 37.4358 30.3184 37.4921 30.2042 37.6048C30.09 37.7175 30.033 37.8609 30.033 38.0351C30.033 38.2092 30.09 38.3527 30.2042 38.4654L31.9788 40.2172C32.1034 40.3402 32.2486 40.4016 32.4147 40.4016C32.5807 40.4016 32.726 40.3402 32.8506 40.2172L36.3687 36.7442C36.4828 36.6315 36.5399 36.4881 36.5399 36.3139C36.5399 36.1398 36.4828 35.9964 36.3687 35.8837C36.2545 35.771 36.1092 35.7146 35.9328 35.7146C35.7564 35.7146 35.6111 35.771 35.4969 35.8837L32.4147 38.9264Z" fill="white" />
            <path fillRule="evenodd" clipRule="evenodd" d="M20.0262 26.306C20.0262 24.2629 21.7816 22.7493 23.7858 22.7493C25.79 22.7493 27.5453 24.2629 27.5453 26.306V27.6977C27.5453 28.381 26.9843 28.9348 26.2921 28.9348C25.6 28.9348 25.039 28.381 25.039 27.6977V26.306C25.039 25.7773 24.5605 25.2235 23.7858 25.2235C23.0111 25.2235 22.5326 25.7773 22.5326 26.306V27.6977C22.5326 28.381 21.9715 28.9348 21.2794 28.9348C20.5873 28.9348 20.0262 28.381 20.0262 27.6977V26.306Z" fill="#645A80" />
            <path fillRule="evenodd" clipRule="evenodd" d="M30.0519 31.1964C27.9511 32.1736 26.3746 34.0685 25.8528 36.3575H20.0265C19.3618 36.3575 18.7243 36.0969 18.2542 35.6329C17.7842 35.1689 17.5201 34.5395 17.5201 33.8833V30.172C17.5201 29.5158 17.7842 28.8864 18.2542 28.4224C18.7243 27.9584 19.3618 27.6978 20.0265 27.6978H27.5456C28.2103 27.6978 28.8478 27.9584 29.3178 28.4224C29.7879 28.8864 30.0519 29.5158 30.0519 30.172V31.1964Z" fill="#645A80" />
        </svg>
    );
}