import { theme } from "antd";

export default function VendorFunded() {
    const { token: { ap_userSecondaryColor }, } = theme.useToken();
    return (
        <svg width="60" height="60" viewBox="0 0 50 50" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M0.320496 10C0.320496 4.47715 4.79765 0 10.3205 0H39.6795C45.2023 0 49.6795 4.47715 49.6795 10V40C49.6795 45.5228 45.2023 50 39.6795 50H10.3205C4.79764 50 0.320496 45.5228 0.320496 40V10Z" fill={ap_userSecondaryColor} />
            <path fillRule="evenodd" clipRule="evenodd" d="M37.6211 43.3333C41.2481 43.3333 44.1883 40.3931 44.1883 36.7661C44.1883 33.1392 41.2481 30.199 37.6211 30.199C33.9942 30.199 31.054 33.1392 31.054 36.7661C31.054 40.3931 33.9942 43.3333 37.6211 43.3333ZM36.6854 37.7348L35.2734 36.3229C35.153 36.2025 34.9998 36.1423 34.8137 36.1423C34.6277 36.1423 34.4744 36.2025 34.354 36.3229C34.2336 36.4433 34.1734 36.5965 34.1734 36.7826C34.1734 36.9686 34.2336 37.1219 34.354 37.2423L36.2257 39.1139C36.357 39.2452 36.5102 39.3109 36.6854 39.3109C36.8605 39.3109 37.0137 39.2452 37.1451 39.1139L40.8555 35.4035C40.9759 35.2831 41.0361 35.1298 41.0361 34.9438C41.0361 34.7577 40.9759 34.6044 40.8555 34.484C40.7351 34.3637 40.5819 34.3035 40.3958 34.3035C40.2098 34.3035 40.0565 34.3637 39.9361 34.484L36.6854 37.7348Z" fill="white" />
            <path fillRule="evenodd" clipRule="evenodd" d="M17.0033 40.8372C19.1673 41.7716 21.4795 42.2388 23.9399 42.2388C26.2839 42.2399 28.4933 41.8165 30.5683 40.9685C29.9755 39.9758 29.5864 38.8474 29.4586 37.6411C27.7719 38.3347 25.9323 38.6816 23.9399 38.6816C19.9677 38.6816 16.6031 37.3031 13.8463 34.5463C11.0894 31.7894 9.71102 28.4249 9.71102 24.4527C9.71102 20.4805 11.0894 17.1159 13.8463 14.3591C16.6031 11.6023 19.9677 10.2238 23.9399 10.2238C27.9121 10.2238 31.2766 11.6023 34.0335 14.3591C36.7903 17.1159 38.1687 20.4805 38.1687 24.4527C38.1687 25.9017 37.9853 27.2699 37.6184 28.5572C37.6195 28.5572 37.6205 28.5572 37.6215 28.5572C38.8512 28.5572 40.0177 28.8276 41.0649 29.3121C41.5056 27.7622 41.7259 26.1424 41.7259 24.4527C41.7271 21.9923 41.2605 19.6801 40.3262 17.5161C39.3918 15.3522 38.1243 13.4698 36.5235 11.8691C34.9228 10.2695 33.0404 9.00253 30.8764 8.06817C28.7125 7.13381 26.4003 6.66663 23.9399 6.66663C21.4795 6.66781 19.1673 7.13499 17.0033 8.06817C14.8393 9.00134 12.957 10.2683 11.3562 11.8691C9.75667 13.4698 8.48971 15.3522 7.55535 17.5161C6.62099 19.6801 6.15381 21.9923 6.15381 24.4527C6.15499 26.9131 6.62218 29.2253 7.55535 31.3893C8.48853 33.5532 9.75549 35.4356 11.3562 37.0363C12.957 38.6359 14.8393 39.9029 17.0033 40.8372Z" fill="white" />
            <path fillRule="evenodd" clipRule="evenodd" d="M34.0684 23.6461C33.7843 23.0009 33.6371 22.6859 33.1052 22.6859H31.6606L28.3865 18.6059C28.206 18.381 27.9771 18.1995 27.7167 18.0747C27.4563 17.95 27.1711 17.8852 26.8822 17.8851H23.474C22.9421 17.8851 22.5108 18.3151 22.5108 18.8453V22.6859H14.1649C13.6331 22.6859 13.2018 23.1159 13.2018 23.6461V25.5664C12.9358 25.5664 12.7202 25.7812 12.7202 26.0465V27.0066C12.7202 27.2719 12.9357 27.4867 13.2018 27.4867H16.9524C17.3708 26.4867 18.3585 25.7841 19.5104 25.7841C20.6622 25.7841 21.6499 26.4867 22.0683 27.4867H26.4121H28.4547C28.8731 26.4867 29.8608 25.7841 31.0126 25.7841C32.1645 25.7841 33.1522 26.4867 33.5706 27.4867H34.5499C34.816 27.4867 35.0315 27.2719 35.0315 27.0066V26.0465C35.0315 25.8146 34.5018 24.6224 34.1881 23.9162L34.188 23.9161C34.143 23.8146 34.1023 23.7232 34.0684 23.6461ZM26.8822 19.8054H24.4371V22.6859H29.1937L26.8822 19.8054Z" fill="#645A80" />
            <ellipse cx="19.511" cy="28.556" rx="2.21727" ry="2.21727" fill="#645A80" />
            <ellipse cx="31.0129" cy="28.556" rx="2.21727" ry="2.21727" fill="#645A80" />
            <path d="M16.0466 19.5782C16.0466 19.026 16.4943 18.5782 17.0466 18.5782H20.867C21.4193 18.5782 21.867 19.026 21.867 19.5782V22.1813H16.0466V19.5782Z" fill="#645A80" />
        </svg>
    );
}