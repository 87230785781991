
import dayjs from "dayjs";
import constants from "../../constants";
const customParseFormat = require('dayjs/plugin/customParseFormat');
dayjs.extend(customParseFormat);

function generateCreateClientData() {
    return ({
        clientBusinessName: {
            value: '',
            error: '',
            hidden: false,
            required: true,
            inputType: 'text',
            label: 'Client Business Name',

        },
        industry: {
            value: '',
            error: '',
            hidden: false,
            required: true,
            inputType: 'dropdown',
            label: 'Industry',
        },
        type: {
            value: '',
            error: '',
            hidden: false,
            required: true,
            inputType: 'dropdown',
            label: 'Type',
        },
        companyType: {
            value: 'Corporation', //Default Value
            error: '',
            hidden: false,
            required: true,
            inputType: 'dropdown',
            label: 'Company Type',
        },
        dateOfIncorporation: {
            value: '',
            error: '',
            hidden: false,
            required: false,
            inputType: 'date',
            format: "DD-MM-YYYY",
            label: 'Date of Incorporation',
        },
        filedFSDate: {
            value: '',
            error: '',
            hidden: false,
            required: false,
            inputType: 'date',
            format: "DD-MM-YYYY",
            label: 'Filed FS Year End',
        },
        primaryContactFirstName: {
            value: '',
            error: '',
            hidden: false,
            required: true,
            inputType: 'text',
            label: 'Primary Contact First Name',
        },
        primaryContactLastName: {
            value: '',
            error: '',
            hidden: false,
            required: true,
            inputType: 'text',
            label: 'Primary Contact Last Name',
        },
        email: {
            value: '',
            error: '',
            hidden: false,
            required: true,
            inputType: 'email',
            label: 'Email',
        },
        telephone: {
            value: '',
            error: '',
            hidden: false,
            required: true,
            inputType: 'tel',
            label: 'Telephone',
        },
        address: {
            value: '',
            error: '',
            hidden: false,
            required: false,
            inputType: 'text',
            label: 'Address',
        },
        city: {
            value: '',
            error: '',
            hidden: false,
            required: false,
            inputType: 'text',
            label: 'City',
        },
        province: {
            value: '',
            error: '',
            hidden: false,
            required: true,
            inputType: 'dropdown',
            label: 'Province/States',
        },
        postalCode: {
            value: '',
            error: '',
            hidden: false,
            required: false,
            inputType: 'text',
            label: 'Postal Code',
        },
        country: {
            value: 'Canada',
            error: '',
            hidden: false,
            required: true,
            inputType: 'dropdown',
            label: 'Country',
        },
    });
}

function getFormFieldsA(formData, setFormData, validate, invokeFieldValidaton) {

    const handleClientBusinessName = (e) => {
        setFormData({
            ...formData,
            clientBusinessName: {
                ...formData?.clientBusinessName,
                value: e?.target?.value,
                error: invokeFieldValidaton ? validate?.ValidateRequiredField(e?.target?.value)?.errorMessage : '',
            }
        })
    }

    const formFieldsA = {
        clientBusinessName: {
            ...formData?.clientBusinessName,
            eventHandler: handleClientBusinessName,
        },
    }

    return formFieldsA;
}

function getFormFieldsB(formData, setFormData, validate, invokeFieldValidaton, setProvinces, provinces) {

    const handleIndustry = (industry) => {
        setFormData({
            ...formData,
            industry: {
                ...formData.industry,
                value: industry,
                error: invokeFieldValidaton ? validate.ValidateRequiredField(industry).errorMessage : '',
            }
        });
    }

    const handleType = (type) => {
        setFormData({
            ...formData,
            type: {
                ...formData.type,
                value: type,
                error: invokeFieldValidaton ? validate.ValidateRequiredField(type).errorMessage : '',
            }
        });
    }

    const handleCompanyType = (companyType) => {
        setFormData({
            ...formData,
            companyType: {
                ...formData.companyType,
                value: companyType,
                error: invokeFieldValidaton ? validate.ValidateRequiredField(companyType).errorMessage : '',
            }
        });
    }

    const handleDateOfIncorporation = (date, dateString) => {
        setFormData({
            ...formData,
            dateOfIncorporation: {
                ...formData.dateOfIncorporation,
                value: dayjs(dateString, 'DD-MM-YYYY'),
            }
        });
    }

    const handleFiledFSYearEnd = (date, dateString) => {
        setFormData({
            ...formData,
            filedFSDate: {
                ...formData.filedFSDate,
                value: dayjs(dateString, 'DD-MM-YYYY')
            }
        });
    }

    const handlePrimaryContactFirstName = (e) => {
        setFormData({
            ...formData,
            primaryContactFirstName: {
                ...formData.primaryContactFirstName,
                value: e.target.value,
                error: invokeFieldValidaton ? validate.ValidateRequiredField(e.target.value).errorMessage : '',
            }
        })
    }

    const handlePrimaryContactLastName = (e) => {
        setFormData({
            ...formData,
            primaryContactLastName: {
                ...formData.primaryContactLastName,
                value: e.target.value,
                error: invokeFieldValidaton ? validate.ValidateRequiredField(e.target.value).errorMessage : '',
            }
        })
    }

    const handleEmail = (e) => {
        setFormData({
            ...formData,
            email: {
                ...formData.email,
                value: e.target.value,
                error: invokeFieldValidaton ? (!!validate.ValidateRequiredField(e.target.value).errorMessage ? validate.ValidateRequiredField(e.target.value).errorMessage : (!validate.EmailValidation(e.target.value) ? 'Invalid Email Address' : '')) : '',
            }
        });
    }

    const handleTelephone = (e) => {
        setFormData({
            ...formData,
            telephone: {
                ...formData.telephone,
                value: validate.FormatTelephone(e.target.value).input,
                error: invokeFieldValidaton ? validate.FormatTelephone(e.target.value).error : '',
            }
        });
    }

    const handleAddress = (e) => {
        setFormData({
            ...formData,
            address: {
                ...formData.address,
                value: e.target.value,
            }
        });
    }

    const handleCity = (e) => {
        setFormData({
            ...formData,
            city: {
                ...formData.city,
                value: e.target.value,
            }
        });
    }

    const handleProvince = (province) => {
        setFormData({
            ...formData,
            province: {
                ...formData.province,
                value: province,
                error: invokeFieldValidaton ? validate.ValidateRequiredField(province).errorMessage : '',
            }
        });
    }

    const handlePostalCode = (e) => {
        let validateFunc = null;
        if (formData.country.value === "Canada") {
            validateFunc = validate.FormatCanadianPostalCode;
        } else if (formData.country.value === "USA") {
            validateFunc = validate.FormatAmericanZipCode;
        }
        setFormData({
            ...formData,
            postalCode: {
                ...formData.postalCode,
                value: validateFunc ? validateFunc(e.target.value).input : e.target.value,
                error: validateFunc && validateFunc(e.target.value).error,
            }
        });
    }

    const handleCountry = (country) => {
        (country === 'Canada') ? setProvinces(constants.CANADIAN_PROVINCES) : setProvinces(constants.AMERICAN_STATES);

        setFormData({
            ...formData,
            province: {
                ...formData.province,
                value: '',
            },
            country: {
                ...formData.country,
                value: country,
                error: invokeFieldValidaton ? validate.ValidateRequiredField(country).errorMessage : '',
            }
        });
    }

    const formFieldsB = {
        industry: {
            ...formData?.industry,
            eventHandler: handleIndustry,
            dropdownContent: constants.CLIENT_INDUSTRIES,
        },
        type: {
            ...formData?.type,
            eventHandler: handleType,
            dropdownContent: constants.CLIENT_TYPES,
        },
        companyType: {
            ...formData.companyType,
            eventHandler: handleCompanyType,
            dropdownContent: constants.COMPANY_TYPES,
        },
        dateOfIncorporation: {
            ...formData?.dateOfIncorporation,
            eventHandler: handleDateOfIncorporation,
        },
        filedFSDate: {
            ...formData?.filedFSDate,
            eventHandler: handleFiledFSYearEnd,
        },
        primaryContactFirstName: {
            ...formData?.primaryContactFirstName,
            eventHandler: handlePrimaryContactFirstName,
        },
        primaryContactLastName: {
            ...formData?.primaryContactLastName,
            eventHandler: handlePrimaryContactLastName,
        },
        email: {
            ...formData?.email,
            eventHandler: handleEmail,
        },
        telephone: {
            ...formData?.telephone,
            eventHandler: handleTelephone,
        },
        address: {
            ...formData?.address,
            eventHandler: handleAddress,
        },
        city: {
            ...formData?.city,
            eventHandler: handleCity,
        },
        province: {
            ...formData?.province,
            eventHandler: handleProvince,
            dropdownContent: provinces,
        },
        postalCode: {
            ...formData?.postalCode,
            eventHandler: handlePostalCode,
        },
        country: {
            ...formData?.country,
            eventHandler: handleCountry,
            dropdownContent: constants.COUNTRIES,
        },
    }

    return formFieldsB;
}

function generateResetForm(formData, setFormData) {

    if (!setFormData || !formData)
        return;

    setFormData(prevFormData => ({
        ...prevFormData,
        clientBusinessName: {
            ...prevFormData?.clientBusinessName,
            value: '',
            error: '',
        },
        industry: {
            ...prevFormData?.industry,
            value: null,
            error: '',
        },
        type: {
            ...prevFormData?.type,
            value: null,
            error: '',
        },
        companyType: {
            ...prevFormData.companyType,
            value: null,
            error: '',
        },
        dateOfIncorporation: {
            ...prevFormData?.dateOfIncorporation,
            value: '',
            error: '',
        },
        filedFSDate: {
            ...prevFormData?.filedFSDate,
            value: '',
            error: '',
        },
        email: {
            ...prevFormData?.email,
            value: '',
            error: '',
        },
        telephone: {
            ...prevFormData?.telephone,
            value: '',
            error: '',
        },
        address: {
            ...prevFormData?.address,
            value: '',
            error: '',
        },
        city: {
            ...prevFormData?.city,
            value: '',
            error: '',
        },
        province: {
            ...prevFormData?.province,
            value: '',
            error: '',
        },
        postalCode: {
            ...prevFormData?.postalCode,
            value: '',
            error: '',
        },
        country: {
            ...prevFormData?.country,
            value: 'Canada',
            error: '',
        },
        primaryContactFirstName: {
            ...prevFormData?.primaryContactFirstName,
            value: '',
            error: '',
        },
        primaryContactLastName: {
            ...prevFormData?.primaryContactLastName,
            value: '',
            error: '',
        },
    }))
}

const exportObj = {
    generateCreateClientData,
    getFormFieldsA,
    getFormFieldsB,
    generateResetForm
}

export default exportObj;