export default function UpdateFileUrl(fileUrl, sourceFolder, destinationFolder) {

    if (!fileUrl || !sourceFolder || !destinationFolder)
        return;

    // Split the URL into parts
    const urlParts = fileUrl.split('/');
    const blobName = urlParts[urlParts.length - 1];

    // Find the index of the source folder name in the URL
    const sourceFolderIndex = urlParts.indexOf(sourceFolder);

    // If the source folder is found in the URL, replace it with the destination folder
    if (sourceFolderIndex !== -1) {
        urlParts[sourceFolderIndex] = destinationFolder;
    }

    // Reconstruct the modified URL
    const modifiedUrl = urlParts.slice(0, -1).join('/') + '/' + blobName;

    return modifiedUrl;
}