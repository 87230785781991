import { theme } from "antd";
import Icon from '@ant-design/icons';

function LogOutIcon() {
    const { token: { ap_userPrimaryColor } } = theme.useToken();
    return (
        <svg width="22" height="18" viewBox="0 0 21 16" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M0.5 1.9999C0.5 1.46946 0.710716 0.960747 1.08579 0.585671C1.46087 0.210594 1.96958 -0.00012207 2.50002 -0.00012207H11.5001C12.0306 -0.00012207 12.5393 0.210594 12.9143 0.585671C13.2894 0.960747 13.5001 1.46946 13.5001 1.9999V3.99992C13.5001 4.26514 13.3948 4.5195 13.2072 4.70704C13.0197 4.89457 12.7653 4.99993 12.5001 4.99993C12.2349 4.99993 11.9806 4.89457 11.793 4.70704C11.6055 4.5195 11.5001 4.26514 11.5001 3.99992V1.9999H2.50002V14H11.5001V12C11.5001 11.7348 11.6055 11.4804 11.793 11.2929C11.9806 11.1054 12.2349 11 12.5001 11C12.7653 11 13.0197 11.1054 13.2072 11.2929C13.3948 11.4804 13.5001 11.7348 13.5001 12V14C13.5001 14.5305 13.2894 15.0392 12.9143 15.4143C12.5393 15.7893 12.0306 16 11.5001 16H2.50002C1.96958 16 1.46087 15.7893 1.08579 15.4143C0.710716 15.0392 0.5 14.5305 0.5 14V1.9999ZM15.7932 4.29292C15.9807 4.10545 16.235 4.00013 16.5002 4.00013C16.7653 4.00013 17.0197 4.10545 17.2072 4.29292L20.2072 7.29296C20.3947 7.48049 20.5 7.7348 20.5 7.99996C20.5 8.26513 20.3947 8.51944 20.2072 8.70697L17.2072 11.707C17.0186 11.8892 16.766 11.99 16.5038 11.9877C16.2416 11.9854 15.9908 11.8802 15.8053 11.6948C15.6199 11.5094 15.5148 11.2586 15.5125 10.9964C15.5102 10.7342 15.611 10.4816 15.7932 10.293L17.0862 8.99997H7.50008C7.23486 8.99997 6.9805 8.89462 6.79296 8.70708C6.60542 8.51954 6.50006 8.26518 6.50006 7.99996C6.50006 7.73474 6.60542 7.48039 6.79296 7.29285C6.9805 7.10531 7.23486 6.99995 7.50008 6.99995H17.0862L15.7932 5.70694C15.6057 5.51941 15.5004 5.2651 15.5004 4.99993C15.5004 4.73476 15.6057 4.48045 15.7932 4.29292Z" fill={ap_userPrimaryColor} />
        </svg>
    )
}

function LogOutIconHovered() {
    const { token: { ap_userPrimaryColor } } = theme.useToken();
    return (
        <svg width="22" height="18" viewBox="0 0 21 16" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M1.08579 0.585671C0.710716 0.960747 0.5 1.46946 0.5 1.9999V14C0.5 14.5305 0.710716 15.0392 1.08579 15.4143C1.46087 15.7893 1.96958 16 2.50002 16H11.5001C12.0306 16 12.5393 15.7893 12.9143 15.4143C13.2894 15.0392 13.5001 14.5305 13.5001 14V12V8.99997H11.5001V14H2.50002V1.9999H11.5001V3.99992V6.99995H13.5001V1.9999C13.5001 1.46946 13.2894 0.960747 12.9143 0.585671C12.5393 0.210594 12.0306 -0.00012207 11.5001 -0.00012207H2.50002C1.96958 -0.00012207 1.46087 0.210594 1.08579 0.585671Z" fill={ap_userPrimaryColor} />
            <path fillRule="evenodd" clipRule="evenodd" d="M15.7932 4.29292C15.9807 4.10545 16.235 4.00013 16.5002 4.00013C16.7653 4.00013 17.0196 4.10545 17.2072 4.29292L20.2072 7.29296C20.3947 7.48049 20.5 7.7348 20.5 7.99996C20.5 8.26513 20.3947 8.51944 20.2072 8.70697L17.2072 11.707C17.0186 11.8892 16.766 11.99 16.5038 11.9877C16.2416 11.9854 15.9908 11.8802 15.8053 11.6948C15.6199 11.5094 15.5148 11.2586 15.5125 10.9964C15.5102 10.7342 15.611 10.4816 15.7932 10.293L17.0862 8.99997H13.5001V6.99995H17.0862L15.7932 5.70694C15.6057 5.51941 15.5004 5.2651 15.5004 4.99993C15.5004 4.73476 15.6057 4.48045 15.7932 4.29292Z" fill={ap_userPrimaryColor} />
            <path d="M11.5001 3.99992V1.9999H2.50002V14H11.5001V8.99997H7.50007C7.23486 8.99997 6.9805 8.89462 6.79296 8.70708C6.60542 8.51954 6.50006 8.26518 6.50006 7.99996C6.50006 7.73474 6.60542 7.48039 6.79296 7.29285C6.9805 7.10531 7.23486 6.99995 7.50007 6.99995H11.5001V3.99992Z" fill={ap_userPrimaryColor} />
        </svg>
    );
}

export default function LogOutListItem({ hovered }) {
    return (
        <>
            {hovered ?
                <Icon component={() => LogOutIconHovered()} /> :
                <Icon component={() => LogOutIcon()} />
            }
        </>
    )
}