import axios from "axios";
import EncryptionService from "./encryption.service";
import NotificationService from "./notification.service";
import AuthService from "./auth.service";
import appInsightsObj from "./app-insight-service";
export default class HttpInterceptorService extends EncryptionService {
  _httpClient = Symbol('protected');
  #baseURL = process.env.REACT_APP_BACKEND_PROXY_IP + 'api/v1.0/';

  constructor() {
    super();
    if (this.constructor === HttpInterceptorService) {
      throw new Error("Abstract classes can't be instantiated.");
    }
    this._httpClient = axios.create({
      withCredentials: true, credentials: 'include', baseURL: this.#baseURL
    });

    this.requestInterceptor();
    this.responseInterceptor();
  }


  /**
   ** This function will intercept requests before sending requests to the server
   * @author Ishank Agarwal
   */
  requestInterceptor() {
    this._httpClient.interceptors.request.use((config) => {
      try {
        let requestHeadersRequired = true;

        if ((config.url === 'users/login' || config.url === 'users/register' || config.url === 'registration_request' || config.url === 'common/verify_captcha' || config.url === 'code/validate' || config.url === 'code/validate-reset-code' || config.url === 'code/expire' || config.url === 'users/reset-password' || config.url === 'users/update-password') && (config.method === 'post'))
          requestHeadersRequired = false;

        if ((config.url).includes('registration_request') || config.url === 'users/update-password')
          requestHeadersRequired = false;

        if (config.headers?.['x-access-token'])
          requestHeadersRequired = false;

        if (requestHeadersRequired) {
          const user = AuthService.getCurrentUser();
          if (user && user !== '') {
            const token = user?.access_token;
            if (!token || token === '') {
              //add notification here
              window.location.href = '/';
            }
            else {
              config.headers["x-access-token"] = token ? token : '';
            }
          }
          else {
            //add notification here
            window.location.href = '/';
          }
        }
        config = this.encrypt(config);
        return config;
      }
      catch (error) {
        appInsightsObj.error(error);
        NotificationService.error('Error', "Something went wrong. Please try again later.");
        // return config;
      }
    }, (error) => {
      appInsightsObj.error(error);
      return Promise.reject(error);
    });
  }



  /**
   ** This function will intercept response coming from the server
   * @author Ishank Agarwal
   */
  responseInterceptor() {
    this._httpClient.interceptors.response.use(async response => {
      try {
        const { data } = await this.decrypt(response);
        if (this._isSecure && data?.data?.data)
          return data.data;
        else return data;
      }
      catch (error) {
        appInsightsObj.error(error);
        NotificationService.error('Error', "Something went wrong. Please try again later.");
      }
    }, async error => {
      try {
        let data = (await this.decrypt(error?.response))?.data;
        if (data?.data)
          data = data.data
        if (data?.code === 401 && data?.errors.includes('Failed to authenticate')) {
          console.error("inside interceptor", error);
          AuthService.logout();
          window.location.href = '/';
          // TODO: Add a notification after user is redirected to login
        }
        else {
          NotificationService.error('Error', data?.errors?.message || data?.errors || "Something went wrong. Please try again later.");
        }
        return data;
      }
      catch (error) {
        NotificationService.error('Error', "Something went wrong. Please try again later.");
        appInsightsObj.error(error);
      }
    });
  }
}