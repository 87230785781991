import { theme } from "antd";

export default function ActionRequired() {
    const { token: { themeDangerColor } } = theme.useToken();
    return (
        <svg width="60" height="60" viewBox="0 0 50 50" fill="none" xmlns="http://www.w3.org/2000/svg">
            <rect width="50" height="50" rx="10" fill={themeDangerColor} />
            <path fillRule="evenodd" clipRule="evenodd" d="M16.25 6.66663C15.0344 6.66663 13.8687 7.14951 13.0091 8.00905C12.1496 8.86859 11.6667 10.0344 11.6667 11.25V38.75C11.6667 39.9655 12.1496 41.1313 13.0091 41.9909C13.8687 42.8504 15.0344 43.3333 16.25 43.3333H34.5834C35.7989 43.3333 36.9647 42.8504 37.8243 41.9909C38.6838 41.1313 39.1667 39.9655 39.1667 38.75V19.0737C39.1659 18.1623 38.8032 17.2886 38.1584 16.6445L29.1888 7.67267C28.5444 7.02867 27.6707 6.66682 26.7596 6.66663H16.25ZM14.6296 9.62951C14.1998 10.0593 13.9584 10.6422 13.9584 11.25V38.75C13.9584 39.3577 14.1998 39.9406 14.6296 40.3704C15.0593 40.8002 15.6422 41.0416 16.25 41.0416H34.5834C35.1911 41.0416 35.774 40.8002 36.2038 40.3704C36.6336 39.9406 36.875 39.3577 36.875 38.75V20.4166H28.8542C27.9425 20.4166 27.0682 20.0545 26.4235 19.4098C25.7789 18.7652 25.4167 17.8908 25.4167 16.9791V8.95829H16.25C15.6422 8.95829 15.0593 9.19974 14.6296 9.62951ZM28.8542 18.125H36.4006L27.7084 9.43267V16.9791C27.7084 17.283 27.8291 17.5745 28.044 17.7894C28.2588 18.0042 28.5503 18.125 28.8542 18.125Z" fill="white" />
            <path d="M21.6607 25.4525C21.2456 25.0375 20.5727 25.0375 20.1577 25.4525C19.7426 25.8676 19.7426 26.5405 20.1577 26.9556L24.1163 30.9142L20.1577 34.8728C19.7426 35.2879 19.7426 35.9608 20.1577 36.3758C20.5727 36.7909 21.2456 36.7909 21.6607 36.3758L25.6193 32.4172L29.578 36.3758C29.993 36.7909 30.6659 36.7909 31.081 36.3758C31.496 35.9608 31.496 35.2879 31.081 34.8728L27.1224 30.9142L31.081 26.9556C31.496 26.5405 31.496 25.8676 31.081 25.4525C30.6659 25.0375 29.993 25.0375 29.578 25.4525L25.6193 29.4112L21.6607 25.4525Z" fill="white" />
            <path d="M21.6607 25.4525C21.2456 25.0375 20.5727 25.0375 20.1577 25.4525C19.7426 25.8676 19.7426 26.5405 20.1577 26.9556L24.1163 30.9142L20.1577 34.8728C19.7426 35.2879 19.7426 35.9608 20.1577 36.3758C20.5727 36.7909 21.2456 36.7909 21.6607 36.3758L25.6193 32.4172L29.578 36.3758C29.993 36.7909 30.6659 36.7909 31.081 36.3758C31.496 35.9608 31.496 35.2879 31.081 34.8728L27.1224 30.9142L31.081 26.9556C31.496 26.5405 31.496 25.8676 31.081 25.4525C30.6659 25.0375 29.993 25.0375 29.578 25.4525L25.6193 29.4112L21.6607 25.4525Z" stroke="white" strokeLinecap="round" strokeLinejoin="round" />
        </svg>
    );
}