import { theme } from "antd";
import dayjs from "dayjs";

function configuration(data, timeState) {
    const { token: { ap_userPrimaryColor } } = theme.useToken();
    const dateFormat = ((timeState === 'daily') ? 'DD-MM-YYYY' : (timeState === 'monthly') ? 'MMM-YYYY' : 'YYYY');
    return {
        data: [data, data],
        style: {
            width: '100%'
        },
        xField: 'time',
        yField: ["Drawdown Amount", "Agent Fee"],
        geometryOptions: [
            {
                geometry: 'line',
                color: ap_userPrimaryColor,
                smooth: true,
            },
            {
                geometry: 'line',
                color: '#EDCB00',
                smooth: true,              
            },
        ],
        tooltip: {
            formatter: (datum) => {
                const formattedTime = dayjs(datum?.time).format(dateFormat);
                const formattedDrawdownAmount = datum?.["Drawdown Amount"]?.toLocaleString('en-US', { style: 'currency', currency: 'USD' });
                const formattedAgentFee = datum?.["Agent Fee"]?.toLocaleString('en-US', { style: 'currency', currency: 'USD' });

                return {
                    title: formattedTime,
                    name: formattedDrawdownAmount ? "Drawdown Amount" : "Agent Fee",
                    value: formattedDrawdownAmount || formattedAgentFee,
                }
            },
        },
        xAxis: {
            label: {
                formatter: (text) => {
                    return dayjs(text).format(dateFormat);
                },
            },
        },
        yAxis: {
            "Drawdown Amount": {
                label: {
                    formatter: (text) => ('$' + text.toLocaleString('en-US', { style: 'currency', currency: 'USD' })),
                },
                min: 0,
            },
            "Agent Fee": {
                label: {
                    formatter: (text) => ('$' + text.toLocaleString('en-US', { style: 'currency', currency: 'USD' })),
                },
                min: 0,
            },
        },
    }
}

function aggregateData(data, timeState) {

    // Sort the array by the "time" property in ascending order
    data.sort((a, b) => (a.time > b.time ? 1 : -1));

    const aggregatedData = {};

    data.forEach(item => {
        const time = item.time;
        const drawdownAmount = item["Drawdown Amount"];
        const agentFee = item["Agent Fee"];

        if (!aggregatedData[time]) {
            aggregatedData[time] = {
                "Drawdown Amount": drawdownAmount,
                "Agent Fee": agentFee,
            };
        } else {
            aggregatedData[time]["Drawdown Amount"] += drawdownAmount;
            aggregatedData[time]["Agent Fee"] += agentFee;
        }
    });

    const result = Object.keys(aggregatedData).map(time => ({
        time,
        "Drawdown Amount": Number(aggregatedData[time]["Drawdown Amount"].toFixed(2)),
        "Agent Fee": Number(aggregatedData[time]["Agent Fee"].toFixed(2)),
    }));

    return result;
}

export default {
    aggregateData: aggregateData,
    configuration: configuration,
}