import { useEffect, useState } from "react";
import { Skeleton, Tabs } from "antd";
import { DualAxes } from "@ant-design/plots";
import dayjs from "dayjs";

import HomeTotalVolumeIcon from '../../../assets/images/icons/Home/HomeTotalVolumeIcon';
import HomeTotalFeeIcon from '../../../assets/images/icons/Home/HomeTotalFeeIcon';
import HomeAvgPercentageIcon from '../../../assets/images/icons/Home/HomeAvgPercentageIcon';
import EmptyGrowthIcon from "../../../assets/images/icons/Home/EmptyGrowthIcon";
import EmptyDataComponent from "../../../components/EmptyDataComponent";

import ApiService from "../../../api";

import chartConstants from "./constants";
import styles from "./index.module.css";
import "../../../assets/styles/index.css";

export default function HomeGrowthChart() {

    const [data, setData] = useState([]);
    const [metrics, setMetrics] = useState({
        totalVolume: 0,
        totalFee: 0,
        totalYield: 0,
    });
    const [loading, setLoading] = useState(false);
    const [timeState, setTimeState] = useState("daily");

    useEffect(() => {
        setLoading(true);

        const dateFormat = ((timeState === 'daily') ? 'YYYY-MM-DD' : (timeState === 'monthly') ? 'YYYY-MM' : 'YYYY');
        ApiService.fetchAgentDrawdowns().then(async drawdowns => {
            let totalDrawdownAmount = 0;
            let totalAgentFee = 0;
            let totalYield = 0;
            let cleanData = [];
            if (!drawdowns) return;
            drawdowns.forEach(async eachDD => {
                totalDrawdownAmount += (eachDD?.ap_paymentScheduleId?.ap_baseAmount || 0);
                totalAgentFee += (eachDD?.ap_agentFee || 0);
                cleanData.push({
                    time: dayjs(eachDD?.createdAt).format(dateFormat),
                    "Drawdown Amount": eachDD?.ap_paymentScheduleId?.ap_baseAmount || 0,
                    "Agent Fee": eachDD?.ap_agentFee || 0,
                });
            });
            setData(chartConstants?.aggregateData(cleanData, timeState));

            totalYield = (totalAgentFee / totalDrawdownAmount) * 100;
            setMetrics({
                totalVolume: (totalDrawdownAmount && !isNaN(totalDrawdownAmount)) ? totalDrawdownAmount : 0,
                totalFee: (totalAgentFee && !isNaN(totalAgentFee)) ? totalAgentFee : 0,
                totalYield: (totalYield && !isNaN(totalYield)) ? totalYield : 0,
            });
        });

        // Fake Data
        // const dbData = [
        //     { time: "2023-10", "Drawdown Amount": 14750, "Agent Fee": 7389.38 },
        // ];
        // setData(chartConstants?.aggregateData(dbData, timeState));
        setTimeout(() => setLoading(false), 1000);
    }, [timeState]);

    const metricsData = [
        {
            icon: <HomeTotalVolumeIcon />,
            value: metrics?.totalVolume?.toLocaleString('en-US', { style: 'currency', currency: 'USD' }),
            label: "Total Volume",
        },
        {
            icon: <HomeTotalFeeIcon />,
            value: metrics?.totalFee?.toLocaleString('en-US', { style: 'currency', currency: 'USD' }),
            label: "Total Fee Earned",
        },
        {
            icon: <HomeAvgPercentageIcon />,
            value: metrics?.totalYield?.toFixed(2) + "%",
            label: "Total Yield",
        },
    ];

    const chartTabs = [
        { key: 'daily', label: 'Daily' },
        { key: 'monthly', label: 'Monthly' },
        { key: 'yearly', label: 'Yearly' },
    ];
    const config = chartConstants.configuration(data, timeState);

    const RenderFunction = () => {
        return (
            <>
                <Tabs
                    activeKey={timeState}
                    defaultActiveKey="daily"
                    items={chartTabs}
                    onChange={(key) => setTimeState(key)}
                />
                {data?.length !== undefined && data?.length >= 2 ? (
                    <DualAxes {...config} />
                ) : (
                    <EmptyDataComponent flex={true} className="centerPie" iconComponent={<EmptyGrowthIcon />} ContentLine1={"There is nothing to show here."} ContentLine2={"You currently have no statistics."} />
                )}

            </>
        )

    }

    return (
        <div className={styles["Container"]}>
            <div className={styles["Metrics_Container"]}>
                {/* Metrics */}
                {metricsData.map((item, index) => (
                    <div key={index} className={styles["Metrics_Inner"]}>
                        <div className={styles["Metrics_Icon"]}>{item?.icon}</div>
                        <div className={styles["Metrics_Data"]}>

                            <div className={styles["Metrics_Value"]}>
                                {loading ? <Skeleton.Input active={true} size="small" /> : (item?.value)}
                            </div>

                            <div className={styles["Metrics_Label"]}>{item?.label}</div>
                        </div>
                    </div>
                ))}
            </div>
            <div>
                <RenderFunction className={styles["Metrics_Container"]} />
            </div>
        </div>
    );

}
