const BACKEND_PROXY = `${process.env.REACT_APP_BACKEND_PROXY_IP}api/v1.0`;
const GET_IP_ADDRESS = "https://api.ipify.org/?format=json";


const HOME_BAR_GRAPH = "home/graph/drawdownsByProcessStageArea";
const HOME_PIE_GRAPH = "home/graph/drawdownsByProcessStagePie";
const HOME_TASKS = "home/tasks";

const CLIENTS = "clients";
const CONTACTS = "contacts";
const VENDORS = "vendors";
const APPROVALS = "approvals";
const ASSETS = "assets";
const APPLICATION_DRAFT = "applicationdrafts";
const ACTIVE_APPLICATION_DRAFT = "applicationdrafts/active";
const APPROVAL_ASSETS = "approvalassets";
const PAYMENTSCHEDULES_ASSETS = "paymentscheduleassets";
const DRAWDOWN_ASSETS = "drawdownassets";
const APPLICATIONS = "applications";
const RECENT_APPLICATIONS = "applications/recent";
const UPLOAD = "upload";
const PAYMENTSCHEDULES = "paymentschedules";
const NEW_PPS_REQUEST = "newppsrequests";
const GENERATE_PAYMENT_SCHEDULE_HTML = "paymentschedules/generate_html";
const DRAWDOWNS = "drawdowns";
const CANCEL_DRAWDOWN = "drawdowns/canceldrawdown"
const REQUIREMENTS = "requirements";
const ENTITY_REQUIREMENTS = "requirements/entity";
const URGENT_REQUIREMENTS = "requirements/urgent";
const COPYABLE_REQUIREMENT = "requirements/copyable-email";
const VERIFY_CAPTCHA = "common/verify_captcha";
const REGISTRATION_REQUEST = "registration_request";
const SALES = "sales";
const RESOURCES = "resources";
const LISTINGS = "listings";
const AVAILABLE_LISTINGS = "listings/available";
const INTERESTS = "interest";
const ASSET_REQUEST = "listingassetrequest";
const VALIDATE_CODE = "code/validate";
const EXPIRE_CODE = "code/expire";
const RESET_CODE = "code/validate-reset-code";
const AGENT_PROFILE = "agents/profile";
const USERS_UPDATE_PASSWORD = "users/update-password";
const USERS_RESET_PASSWORD = "users/reset-password";
const USERS_REGISTER = "users/register";
const SETTINGS = "settings";
const NOTIFICATIONS = "notifications";
const LENDERS = "lenders";
const PGS = "pg";
const PG_DRAFTS = "pgdrafts";
const ASSET_TRANSFER_REQUEST = "assettransferrequests";

const HEADER_JSON = {
    'Content-Type': 'application/json',
    'Accept': 'application/json'
}

module.exports = {
    BACKEND_PROXY,
    GET_IP_ADDRESS,
    HOME_BAR_GRAPH,
    HOME_PIE_GRAPH,
    HOME_TASKS,
    CLIENTS,
    CONTACTS,
    VENDORS,
    APPROVALS,
    ASSETS,
    APPLICATION_DRAFT,
    ACTIVE_APPLICATION_DRAFT,
    APPROVAL_ASSETS,
    PAYMENTSCHEDULES_ASSETS,
    DRAWDOWN_ASSETS,
    APPLICATIONS,
    RECENT_APPLICATIONS,
    UPLOAD,
    PAYMENTSCHEDULES,
    NEW_PPS_REQUEST,
    GENERATE_PAYMENT_SCHEDULE_HTML,
    DRAWDOWNS,
    CANCEL_DRAWDOWN,
    REQUIREMENTS,
    ENTITY_REQUIREMENTS,
    URGENT_REQUIREMENTS,
    COPYABLE_REQUIREMENT,
    VERIFY_CAPTCHA,
    REGISTRATION_REQUEST,
    SALES,
    RESOURCES,
    AVAILABLE_LISTINGS,
    LISTINGS,
    INTERESTS,
    ASSET_REQUEST,
    VALIDATE_CODE,
    EXPIRE_CODE,
    RESET_CODE,
    AGENT_PROFILE,
    USERS_UPDATE_PASSWORD,
    USERS_RESET_PASSWORD,
    USERS_REGISTER,
    SETTINGS,
    NOTIFICATIONS,
    LENDERS,
    PGS,
    PG_DRAFTS,
    ASSET_TRANSFER_REQUEST,

    HEADER_JSON
}