// TODO: Tighten up the security of the WebSocket connection.
import appInsightsObj from "./app-insight-service";
// Define a WebSocketConnection object to manage WebSocket interactions.
class WebSocketConnection {
    socket= null;   // Holds the WebSocket instance.
    listeners= [];  // Holds an array of listener objects.
    isAlive= false;
    attempts = 0;

    constructor() {
        const socket = new WebSocket(process.env.REACT_APP_WEBSOCKET_PROXY_IP);
        this.socket = socket;
    }
    // Method to establish a WebSocket connection.
    connect () {
        // const socket = new WebSocket(process.env.REACT_APP_WEBSOCKET_PROXY_IP);
        const instance = this; // Save a reference to the current instance
        // Event handler when the WebSocket connection is opened.
        this.socket.onopen = () => {
            appInsightsObj.event('WebSocket connected', {});
            instance.isAlive=  true;
            setInterval(WebSocketConnection.sendKeepAlive, 15000);
        };

        // Event handler when a message is received over the WebSocket.
        this.socket.onmessage = (event) => {
            // Parse the received JSON data.
            const notification = JSON.parse(event.data);

            // Call each registered listener's callback function with the notification data.
            instance.listeners.forEach(entry => entry.listener(notification));
        };

        // Event handler when the WebSocket connection is closed.
        this.socket.onclose = () => {
            instance.isAlive = false;
            appInsightsObj.event('WebSocket is closed now.', {});
            // Add your reconnection logic here if needed.
            setTimeout(function() {
                appInsightsObj.event('Attempting to reconnect...', { "Attempt" : instance.attempts});
                instance.connect();
            }, 1000 * Math.min(instance.attempts, 10)); // Increase delay each time
            instance.attempts += 1;
        };

        // Event handler when an error occurs.
        this.socket.onerror = (event) => {
            appInsightsObj.error(event);
            // Add your error handling logic here.
        };
    };

    // Method to subscribe a listener to WebSocket notifications for a specific agent.
    subscribe= (listener, targetAgentId) => {
        this.listeners.push({ listener, targetAgentId });

        // Send a subscription request to the backend, providing the targetAgentId.
        this.socket.send(JSON.stringify({
            type: 'subscribe',
            targetAgentId: targetAgentId
        }));
    };
    

    // Method to unsubscribe a listener from WebSocket notifications.
    unsubscribe= (listener) => {
        const index = this.listeners.indexOf(listener);
        if (index !== -1) {
            // Remove the listener from the array.
            this.listeners.splice(index, 1);
        }
    };

    sendKeepAlive= () => {
        if (this.socket.readyState === WebSocket.OPEN) {
            appInsightsObj.info('WebSocket HeartBeat Sent...');
            this.socket.send("keep-alive");
        }
    }
};

// Establish the WebSocket connection when the module is imported.
// WebSocketConnection.connect();
const WebSocketConnectionObj = new WebSocketConnection();
WebSocketConnectionObj.attempts = 1;
WebSocketConnectionObj.connect();

export default WebSocketConnectionObj;