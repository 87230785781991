import dayjs from "dayjs";
import validate from "../../utils/Validations";
import pgConstants from "./constants";
import NotificationService from "../../services/notification.service";
import ApiService from "../../api";

const handleDeletePG = (pgIndex, setPgData, pgData, setRefreshPgs) => {
    if (pgIndex !== undefined && pgIndex !== null) {
        const data = pgData[pgIndex];

        if (data?._id && data?.ap_applicationId) {
            ApiService.deletePG(data?._id).then(resp => {
                if (setRefreshPgs) setRefreshPgs(prev => prev + 1)
            });
        } else if (data?._id && data?.ap_applicationDraftId) {
            ApiService.deletePGDraft(data?._id).then(resp => {
                if (setRefreshPgs) setRefreshPgs(prev => prev + 1)
            })
        }

        // if (data?._id && data?.ap_applicationDraftId) {
        //     ApiService.deletePGDraft(data?._id).then(resp => {
        //         if (setRefreshPgs) setRefreshPgs(prev => prev + 1)
        //     })
        // } else if (data?._id && !data?.ap_applicationDraftId) {
        //     ApiService.deletePG(data?._id).then(resp => {
        //         if (setRefreshPgs) setRefreshPgs(prev => prev + 1)
        //     });
        // }
        setPgData(prevPgData => {
            const pgArr = [...prevPgData];
            pgArr.splice(pgIndex, 1);
            return pgArr;
        });
    }
}

const handleDeleteCoApp = (coAppIndex, setCoApps) => {
    if (coAppIndex !== undefined && coAppIndex !== null)
        setCoApps(prevCoApps => {
            const coAppArr = [...prevCoApps];
            coAppArr.splice(coAppIndex, 1);
            return coAppArr;
        });
}

const handleContact = (value, pgIndex, setPgData, setUnsaved) => {
    setPgData(prevPgData => {
        const pgArr = [...prevPgData];
        pgArr[pgIndex].ap_contactId = value;
        const pgId = prevPgData[pgIndex]?._id;
        if (pgId) pgArr[pgIndex].updated = true;
        return pgArr;
    });
    if (setUnsaved)
        setUnsaved(true);
}

const handleSin = (value, pgIndex, setPgData, setUnsaved) => {
    setPgData(prevPgData => {
        const pgArr = [...prevPgData];
        pgArr[pgIndex].ap_sin = validate.ValidateSIN(value).value;

        const pgId = prevPgData[pgIndex]?._id;
        if (pgId) pgArr[pgIndex].updated = true;

        return pgArr;
    });
    if (setUnsaved)
        setUnsaved(true);
}

const handleDOB = (dateString, pgIndex, setPgData, setUnsaved) => {
    setPgData(prevPgData => {
        const pgArr = [...prevPgData];
        pgArr[pgIndex].ap_birthDate = dateString ? dayjs(dateString, "DD-MM-YYYY").$d : '';
        const pgId = prevPgData[pgIndex]?._id;
        if (pgId) pgArr[pgIndex].updated = true;
        return pgArr;
    });
    if (setUnsaved)
        setUnsaved(true);
}

const handleDrivingExperience = (value, pgIndex, setPgData, setUnsaved) => {
    setPgData(prevPgData => {
        const pgArr = [...prevPgData];
        pgArr[pgIndex].ap_drivingExperienceYears = value ? validate.ValidateDrivingExperience(value).value : '';
        const pgId = prevPgData[pgIndex]?._id;
        if (pgId) pgArr[pgIndex].updated = true;
        return pgArr;
    });
    if (setUnsaved)
        setUnsaved(true);
}

const handleEverBankrupt = (value, pgIndex, setPgData, setUnsaved) => {
    setPgData(prevPgData => {
        const pgArr = [...prevPgData];
        pgArr[pgIndex].ap_everBankrupt = value;
        const pgId = prevPgData[pgIndex]?._id;
        if (pgId) pgArr[pgIndex].updated = true;
        return pgArr;
    });
    if (setUnsaved)
        setUnsaved(true);
}

const handleEverRespossessed = (value, pgIndex, setPgData, setUnsaved) => {
    setPgData(prevPgData => {
        const pgArr = [...prevPgData];
        pgArr[pgIndex].ap_everRepossessed = value;
        const pgId = prevPgData[pgIndex]?._id;
        if (pgId) pgArr[pgIndex].updated = true;
        return pgArr;
    });
    if (setUnsaved)
        setUnsaved(true);
}

const handleDefandentInLegalAction = (value, pgIndex, setPgData, setUnsaved) => {
    setPgData(prevPgData => {
        const pgArr = [...prevPgData];
        pgArr[pgIndex].ap_defendantInLegalAction = value;
        const pgId = prevPgData[pgIndex]?._id;
        if (pgId) pgArr[pgIndex].updated = true;
        return pgArr;
    });
    if (setUnsaved)
        setUnsaved(true);
}

const handleHomeowner = (value, pgIndex, setPgData, setUnsaved) => {
    setPgData(prevPgData => {
        const pgArr = [...prevPgData];
        pgArr[pgIndex].ap_homeowner = value;
        const pgId = prevPgData[pgIndex]?._id;
        if (pgId) pgArr[pgIndex].updated = true;
        return pgArr;
    });
    if (setUnsaved)
        setUnsaved(true);
}

const handleResidentFor2Years = (value, pgIndex, setPgData, setUnsaved) => {
    setPgData(prevPgData => {
        const pgArr = [...prevPgData];
        pgArr[pgIndex].ap_residentAtleast2Years = value;
        const pgId = prevPgData[pgIndex]?._id;
        if (pgId) pgArr[pgIndex].updated = true;
        return pgArr;
    });
    if (setUnsaved)
        setUnsaved(true);
}

const handleClient = (value, coAppIndex, setCoApps, setUnsaved) => {
    setCoApps(prevCoApps => {
        const coAppArr = [...prevCoApps];

        const exists = coAppArr.some(obj => obj.ap_clientId === value);
        if (exists) {
            NotificationService.error("Client already added as Co-Applicant");
            return coAppArr;
        }
        coAppArr[coAppIndex] = { ap_clientId: value }
        return coAppArr;
    });
    if (setUnsaved)
        setUnsaved(true);
}

const handleAddPGs = (setPgData, clientId, contactList, setUnsaved) => {

    setPgData(prevPgData => {
        const pgArr = [...prevPgData];

        if (pgArr.length >= 4) {
            NotificationService.error("Limit Reached", "Maximum 4 PGs allowed");
            return pgArr;
        }

        if (!clientId) {
            NotificationService.error("Client Unavailable", "Please select a client before adding PGs");
            return pgArr;
        }

        if (pgArr.length >= contactList.length) {
            NotificationService.error("Contact Unavailable", "No contacts available to add as PGs.\nPlease add additional contacts to the client to add as PGs");
            return pgArr;
        }

        pgArr.push(pgConstants.NewPGData());
        return pgArr;

    });
    if (setUnsaved)
        setUnsaved(true);
}

const handleAddCoApps = (setCoApps, clientList, setUnsaved) => {

    setCoApps(prevCoApps => {
        const coAppArr = [...prevCoApps];
        if (coAppArr.length >= 3) {
            NotificationService.error("Maximum 3 Co-Applicants allowed");
            return coAppArr;
        }

        if (!clientList || clientList.length === 0) {
            NotificationService.error("No clients available to add as Co-Applicants");
            return coAppArr;
        }

        if (coAppArr.length >= clientList.length) {
            NotificationService.error("No more clients available to add as Co-Applicants");
            return coAppArr;
        }

        coAppArr.push(pgConstants.NewCoAppData());
        return coAppArr;
    });
    if (setUnsaved)
        setUnsaved(true);
}

export default {
    handleDeletePG,
    handleDeleteCoApp,
    handleContact,
    handleSin,
    handleDOB,
    handleDrivingExperience,
    handleEverBankrupt,
    handleEverRespossessed,
    handleDefandentInLegalAction,
    handleHomeowner,
    handleResidentFor2Years,
    handleClient,
    handleAddPGs,
    handleAddCoApps,
}