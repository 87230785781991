import { theme } from "antd";

export default function SynergyFunded() {
    const { token: { ap_userSecondaryColor }, } = theme.useToken();
    return (
        <svg width="60" height="60" viewBox="0 0 50 50" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M0.320496 10C0.320496 4.47715 4.79765 0 10.3205 0H39.6795C45.2023 0 49.6795 4.47715 49.6795 10V40C49.6795 45.5228 45.2023 50 39.6795 50H10.3205C4.79764 50 0.320496 45.5228 0.320496 40V10Z" fill={ap_userSecondaryColor} />
            <path d="M8.65399 22.4285C8.65399 16.586 8.65399 13.6647 9.34457 12.6818C10.0333 11.7008 12.7792 10.7599 18.2727 8.88002L19.3196 8.52194C22.1824 7.54089 23.6128 7.05127 25.0963 7.05127C26.5779 7.05127 28.0102 7.54089 30.873 8.52194L31.9199 8.88002C37.4134 10.7599 40.1593 11.7008 40.848 12.6818C41.5386 13.6647 41.5386 16.5878 41.5386 22.4285V25.3041C41.5386 35.6042 33.7943 40.6045 28.9347 42.7256C27.6175 43.3011 26.9598 43.5897 25.0963 43.5897C23.2328 43.5897 22.5751 43.3011 21.2579 42.7256C16.3983 40.6027 8.65399 35.6061 8.65399 25.3041V22.4285Z" stroke="white" strokeWidth="4" />
            <path d="M20.529 26.0514L23.1397 28.9744L29.6636 21.6667" stroke="white" strokeWidth="4" strokeLinecap="round" strokeLinejoin="round" />
        </svg>
    );
}