import { notification } from "antd";
import appInsightsObj from "./app-insight-service";

// Define a class for handling notifications
class NotificationService {

    /**
     * Opens a notification.
     * 
     * @param {string} type - The type of the notification ('success', 'error', 'info', 'warning', 'open').
     * @param {string} message - The title or main message of the notification.
     * @param {string} description - The additional description or content of the notification.
     * @param {Function} onClick - The function to be called when the notification is clicked.
     */

    openNotification = (type, message, description, onClick, stringifyNotification = true) => {
        // Use the 'notification' module to display a notification of the specified type
        try {
            if (typeof (message) === "object" && stringifyNotification) message = JSON.stringify(message);
            if (typeof (description) === "object" && stringifyNotification) description = JSON.stringify(description);
            notification[type]({
                message: message,
                description: description,
                placement: "bottomRight",
                getContainer: ".AppTheme",
                onClick: onClick
            });
        } catch (error) {
            appInsightsObj.error(error);
            notification["error"]({
                message: "Error Occured",
                description: "Something went wrong. Please contact " + process.env.REACT_APP_SOLUTIONS_EMAIL,
                placement: "bottomRight",
                getContainer: ".AppTheme",
                onClick: onClick
            });
        };
    }

    // Define different types of notifications
    success = (message, description) => {
        this.openNotification('success', message, description);
    };

    error = (message, description) => {
        this.openNotification('error', message, description);
    };

    info = (message, description) => {
        this.openNotification('info', message, description);
    };

    warning = (message, description, stringifyNotification) => {
        this.openNotification('warning', message, description, null, stringifyNotification);
    };

    // Define a method to open a general notification with the provided parameters
    open = (message, description, onClick) => {
        this.openNotification('open', message, description, onClick)
    }
}

// Create an instance of the 'NotificationService' class
const notificationServiceInstance = new NotificationService();

export default notificationServiceInstance;